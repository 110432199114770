import { useCallback, useMemo, useRef, useState } from 'react';
import { useForm as useHookForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';

import { Form as FormInterface, FormColumn, FormField, FormFieldset, FormPage } from '@interfaces/forms';
import { pushToDataLayer, useTheme, validateForm } from '@utilities';

export interface FormProps extends FormInterface {}

function useForm({
  blockTheme,
  defaultValues,
  fieldIndicationType,
  indicator,
  name,
  notificationRecipients,
  pages,
  thankYouCaption,
  thankYouSummary,
  thankYouTitle,
}: Partial<FormProps>) {
  const [hasMounted, setHasMounted] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSubmitErrorMessage, setShowSubmitErrorMessage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>();
  const theme = useTheme({ blockTheme });

  const currentPage = pages![currentPageIndex];
  const hasMultiplePages = pages!.length > 1;
  const showPreviousButton = hasMultiplePages && currentPageIndex !== 0;
  const showNextButton = hasMultiplePages && currentPageIndex !== pages!.length - 1;
  const showSubmitButton = !hasMultiplePages || (hasMultiplePages && currentPageIndex === pages!.length - 1);
  const hookForm = useHookForm({ mode: 'all', reValidateMode: 'onChange', defaultValues });
  const { formState, getValues, register, reset, setValue, trigger } = hookForm;
  const isDirty = (alias: string) => formState.dirtyFields[alias] === true;
  const isInvalid = (alias: string) => !!formState.errors[alias];

  const formDataValue = useMemo(
    () => ({ fieldIndicationType, indicator, thankYouCaption, thankYouSummary, thankYouTitle }),
    [fieldIndicationType, indicator, thankYouCaption, thankYouSummary, thankYouTitle],
  );

  const trackEvent = useCallback(
    (eventName: string) => {
      pushToDataLayer({ event: 'formEvent', formEventParameters: { eventName, component: `Form[${name}]` } });
    },
    [name],
  );

  const onStartForm = useCallback(() => {
    if (!hasStarted) trackEvent('form_started');
    setHasStarted(true);
  }, [hasStarted, trackEvent]);

  const getColumnsClass = useCallback((columnCount: number): string => {
    if (columnCount === 1) return 'grid-cols-1';
    if (columnCount === 2) return 'grid-cols-1 md:grid-cols-2';
    console.warn('Form does not support more than 2 columns.');
    return 'grid-cols-1';
  }, []);

  const nextPage = useCallback(async () => {
    setShowErrorMessage(false);
    const passedValidation = await validateForm([currentPage], getValues, setValue, trigger);

    if (!passedValidation) {
      trackEvent('form_page_validation_fail');
      setShowErrorMessage(true);
      return;
    }
    trackEvent('form_page_validation_pass');
    trackEvent('form_next_page');
    setCurrentPageIndex(currentPageIndex < pages!.length - 1 ? currentPageIndex + 1 : pages!.length - 1);
  }, [currentPage, currentPageIndex, getValues, pages, setValue, trackEvent, trigger]);

  const onSubmit = useCallback(async () => {
    trackEvent('form_submit_attempt');
    setIsSubmitting(true);
    setShowErrorMessage(false);
    setShowSubmitErrorMessage(false);
    const passedValidation = await validateForm(pages!, getValues, setValue, trigger);

    if (!passedValidation) {
      trackEvent('form_validation_fail');
      setIsSubmitting(false);
      setShowErrorMessage(true);
      return;
    }
    trackEvent('form_validation_pass');

    if (!recaptchaRef.current) {
      setIsSubmitting(false);
      setShowSubmitErrorMessage(true);
      return;
    }

    const recaptchaToken = await recaptchaRef.current.executeAsync();
    const data = getValues();
    const fields: { label: string; key: string; value: any }[] = [];

    pages!.forEach((page: FormPage) => {
      page.fieldsets.forEach((fieldset: FormFieldset) => {
        fieldset.columns.forEach((column: FormColumn) => {
          column.fields
            .filter((field: FormField) => field.name !== 'TitleAndDescription')
            .forEach((field: FormField) => {
              fields.push({ label: field.caption || field.alias, key: field.alias, value: data[field.alias] });
            });
        });
      });
    });

    try {
      const response = await fetch('/api/submit-form', {
        method: 'POST',
        body: JSON.stringify({ formName: name, recipients: notificationRecipients, fields, recaptchaToken }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        trackEvent('form_submit_success');
        setHasSubmitted(true);
      } else {
        trackEvent('form_submit_fail');
        setShowSubmitErrorMessage(true);
      }
      setIsSubmitting(false);
    } catch (error) {
      trackEvent('form_submit_fail');
      setIsSubmitting(false);
      setShowSubmitErrorMessage(true);
      recaptchaRef.current.reset();
    }
  }, [getValues, name, notificationRecipients, pages, setValue, trackEvent, trigger]);

  const previousPage = useCallback(() => {
    trackEvent('form_previous_page');
    setShowErrorMessage(false);
    setCurrentPageIndex(currentPageIndex > 0 ? currentPageIndex - 1 : 0);
  }, [currentPageIndex, trackEvent]);

  const restart = useCallback(() => {
    trackEvent('form_restart');
    reset({ values: defaultValues });
    setShowErrorMessage(false);
    setIsSubmitting(false);
    setHasSubmitted(false);
    setCurrentPageIndex(0);
    setHasStarted(false);
  }, [defaultValues, reset, trackEvent]);

  return {
    currentPage,
    currentPageIndex,
    formDataValue,
    formState,
    hasMounted,
    hasMultiplePages,
    hasSubmitted,
    isSubmitting,
    recaptchaRef,
    showErrorMessage,
    showNextButton,
    showPreviousButton,
    showSubmitErrorMessage,
    showSubmitButton,
    getColumnsClass,
    getValues,
    isDirty,
    isInvalid,
    nextPage,
    onStartForm,
    onSubmit,
    previousPage,
    reset,
    register,
    restart,
    setCurrentPageIndex,
    setHasMounted,
    setHasSubmitted,
    setShowErrorMessage,
    setShowSubmitErrorMessage,
    setValue,
    theme,
    trigger,
  };
}

export default useForm;
