import { ClassesBuilder } from '@utilities';

const classesConfig = {
  label: 'block mb-1 text-sm font-semibold text-click-blue',
  requiredIndicator: 'ml-2 text-red-500',
  optionalIndicator: 'ml-2 text-click-text',
  errorText: 'text-xs text-red-500 mt-2 mb-0 font-semibold',
  helpText: 'text-xs text-gray-500 mt-2 mb-0',
};

export default new ClassesBuilder(classesConfig);
