import { ClassesBuilder } from '@utilities';

const classesConfig = {
  componentContainer: {
    default: 'grid gap-4',
  },
  title: {
    default: 'text-2xl font-bold text-click-blue mb-4',
    lg: 'lg:text-3xl',
  },
  summary: {
    default: 'text-xs text-gray-500',
  },
  tableBlock: {
    default: 'shadow-2xl rounded-xl bg-white p-4 mb-sm',
  },
  table: {
    default: 'table-auto w-full text-sm text-click-para',
  },
  headerRow: {
    default: 'bg-click-grey text-click-blue text-base text-left',
  },
  firstHeaderColumn: {
    default: 'p-3 rounded-lg',
    md: 'md:p-4 md:rounded-tr-none md:rounded-br-none',
  },
  lastHeaderColumn: {
    default: 'hidden p-3  rounded-lg rounded-tl-none rounded-bl-none',
    md: 'md:table-cell md:p-4',
  },
  column: {
    default: 'hidden p-3',
    md: 'md:table-cell md:p-4',
  },
  link: {
    default: 'font-semibold text-click-blue transition',
  },
  row: {
    default: 'w-full border-b border-dashed last-of-type:border-none border-gray-200',
  },
  mainColumn: {
    default: 'p-3',
    md: 'md:p-4',
  },
  noVacancies: {
    default: 'text-center p-4 text-click-para mb-0',
  },
};

export default new ClassesBuilder(classesConfig);
