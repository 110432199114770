import { ClassesBuilder } from '@utilities';

const classesConfig = {
  contentContainer: {
    default: 'order-3',
    md: 'md:order-1 md:mr-12',
    lg: 'lg:mr-16',
  },
  breadcrumbs: {
    default: 'mb-2',
    sm: 'sm:mb-3',
  },
  caption: {
    default: 'mb-2 text-base font-bold',
    sm: 'sm:mb-3',
  },
  title: {
    default: 'mb-4 text-2xl font-bold text-click-blue',
    lg: 'lg:text-3xl',
  },
  richTextContainer: {
    default: 'mb-0',
  },
  infoContainer: {
    default: 'flex gap-4 mb-4',
  },
  date: {
    default: 'text-sm font-semibold mb-2',
  },
  author: {
    default: 'text-sm font-semibold mb-2',
  },
  imageContainer: {
    default: 'flex items-center justify-center order-2',
  },
  imageContainerInner: 'relative w-full',
  image: 'w-full aspect-[1110/550]',
};

export default new ClassesBuilder(classesConfig);
