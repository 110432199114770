import { TitleAndDescriptionField as TitleAndDescriptionFieldInterface } from '@interfaces/forms';

export interface TitleAndDescriptionFieldProps extends TitleAndDescriptionFieldInterface {}

function TitleAndDescriptionField({ heading, bodyText }: TitleAndDescriptionFieldProps): JSX.Element {
  return (
    <div>
      {heading && <h4>{heading}</h4>}
      {bodyText && <p>{bodyText}</p>}
    </div>
  );
}

export default TitleAndDescriptionField;
