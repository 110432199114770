import NextImage from 'next/image';

import { Promotion as PromotionInterface } from '@interfaces';
import { DarkBackground, Theme } from '@contexts';
import { ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { LinkProps } from '@components/Link';
import { useTheme } from '@utilities';

import classes from './Promotion.classes';

export interface PromotionProps extends PromotionInterface {}

function Promotion({
  caption,
  captionImage,
  title,
  summary,
  button,
  link,
  image,
  blockTheme,
  blockIdentifier,
  customID,
}: PromotionProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }

  return (
    <DarkBackground.Provider value>
      <Theme.Provider value={theme}>
        <div className={classes.promotion} id={customID}>
          <Content
            className={classes.contentContainer}
            caption={caption}
            captionImage={captionImage}
            title={title}
            richText={summary}
            buttonProps={buttonProps}
            linkProps={linkProps}
          />
          {image && (
            <div className={classes.imageContainer}>
              <div className={classes.imageContainerInner}>
                <NextImage
                  src={image.src}
                  alt={image.alt || ''}
                  width={image.width}
                  height={image.height}
                  sizes="100vw"
                  quality={75}
                  className={classes.image}
                />
              </div>
            </div>
          )}
        </div>
      </Theme.Provider>
    </DarkBackground.Provider>
  );
}

export default Promotion;
