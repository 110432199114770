import { useContext } from 'react';

import { Theme as ThemeInterface } from '@interfaces';
import { Content } from '@components/Content';
import { FormData, Theme } from '@contexts';
import { Icon } from '@components/Icon';
import { useTheme } from '@utilities';

import classes from './ThankYou.classes';

export interface ThankYouProps {
  onRestart: () => void;
  blockTheme?: ThemeInterface;
}

function ThankYou({ onRestart, blockTheme }: ThankYouProps): JSX.Element {
  const formData = useContext(FormData);
  const theme = useTheme({ blockTheme });

  return (
    <Theme.Provider value={theme}>
      <div className={classes.thanks}>
        <Content
          caption={formData.thankYouCaption}
          title={formData.thankYouTitle}
          summary={formData.thankYouSummary}
          buttonProps={{
            onClick: onRestart,
            children: (
              <>
                <Icon icon="fas fa-rotate-right fa-flip-horizontal" className={classes.restartButtonIcon} />
                Start again
              </>
            ),
          }}
        />
      </div>
    </Theme.Provider>
  );
}

export default ThankYou;
