import { BlogPost, BlogPostListByCategory as BlogPostListByCategoryInterface } from '@interfaces';
import { BlogPostCard } from '@components/BlogPostCard';
import { Button, ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './BlogPostListByCategory.classes';

export interface BlogPostListbyCategoryProps extends BlogPostListByCategoryInterface {}

function BlogPostListbyCategory({
  caption,
  captionImage,
  categoryName,
  title,
  blogPosts,
  blockTheme,
  blockIdentifier,
}: BlogPostListbyCategoryProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  const href = categoryName ? `/blog/category/${categoryName.toLowerCase().replaceAll(' ', '-')}` : '/blog/all-posts';

  const buttonProps: ButtonProps = {
    href,
    target: '_self',
    linkType: 'content',
    elementClick: `button[View all]_${blockIdentifier}`,
    children: 'View all',
    style: 'rounded',
    size: 'small',
  };

  return (
    <Theme.Provider value={theme}>
      <div>
        <div className={classes.heading}>
          <Content className={classes.contentContainer} title={title} caption={caption} captionImage={captionImage} />
          <Button {...buttonProps}>View all</Button>
        </div>

        <div className={classes.blogPosts}>
          {blogPosts.map((blogPost: BlogPost) => (
            <div className={classes.blogPostsCard} key={blogPost.key}>
              <BlogPostCard
                date={blogPost.date}
                elementClick={`blogPost[${blogPost.heading}]_${blockIdentifier}`}
                heading={blogPost.heading}
                image={blogPost.image}
                theme={theme}
                url={blogPost.url}
              />
            </div>
          ))}
        </div>
      </div>
    </Theme.Provider>
  );
}

export default BlogPostListbyCategory;
