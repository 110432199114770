import { ClassesBuilder } from '@utilities';

const classesConfig = {
  nav: 'mb-4 font-semibold',
  ol: 'flex items-start text-sm list-none',
  link: 'transition hover:text-click-blue',
  current: 'text-click-para',
  li: 'flex items-center',
  iconContainer: 'mx-2',
  icon: 'fa-fw w-[1em]',
};

export default new ClassesBuilder(classesConfig);
