import { Pagination as PaginationInterface } from '@interfaces';
import Link from 'next/link';

function Pagination({ basePath, pageNumber, totalPages }: PaginationInterface): JSX.Element {
  const nextPage = pageNumber + 1;
  const prevPage = pageNumber - 1;
  let prevPageLink = (pageNumber - 1).toString();

  if (prevPageLink === '1') {
    prevPageLink = '';
  }

  return (
    <nav aria-label="Pagination">
      <ul className="flex flex-wrap gap-1 text-sm">
        {pageNumber > 1 ? (
          <li>
            <Link
              href={`${basePath}${prevPageLink}`}
              className="rounded-sm border border-gray-200 px-3 md:px-4 h-8 md:h-10 flex justify-center items-center transition hover:border-click-orange hover:bg-click-orange hover:text-white"
            >
              <i className="fa-solid fa-chevron-left" />
            </Link>
          </li>
        ) : null}
        {prevPage > 0 ? (
          <li>
            <Link
              href={`${basePath}${prevPageLink}`}
              className="rounded-sm border border-gray-200 px-3 md:px-4 h-8 md:h-10 flex justify-center items-center transition hover:border-click-orange hover:bg-click-orange hover:text-white"
            >
              {prevPage}
            </Link>
          </li>
        ) : null}
        <li>
          <div className="rounded-sm border border-gray-200 px-3 md:px-4 h-8 md:h-10 flex justify-center items-center transition hover:border-click-orange hover:bg-click-orange hover:text-white">
            {pageNumber}
          </div>
        </li>
        {pageNumber < totalPages ? (
          <li>
            <Link
              href={`${basePath}${nextPage}`}
              className="rounded-sm border border-gray-200 px-3 md:px-4 h-8 md:h-10 flex justify-center items-center transition hover:border-click-orange hover:bg-click-orange hover:text-white"
            >
              {nextPage}
            </Link>
          </li>
        ) : null}
        {pageNumber < totalPages ? (
          <li>
            <Link
              href={`${basePath}${nextPage}`}
              className="rounded-sm border border-gray-200 px-3 md:px-4 h-8 md:h-10 flex justify-center items-center transition hover:border-click-orange hover:bg-click-orange hover:text-white"
            >
              <i className="fa-solid fa-chevron-right" />
            </Link>
          </li>
        ) : null}
      </ul>
    </nav>
  );
}

export default Pagination;
