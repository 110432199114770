import ReCAPTCHA from 'react-google-recaptcha';
import { LegacyRef, useEffect } from 'react';
import cn from 'classnames';

import {
  FormFieldset,
  FormColumn,
  FormField as FormFieldInterface,
  TitleAndDescriptionField as TitleAndDescriptionFieldInterface,
} from '@interfaces/forms';
import { ProgressBar } from '@components/ProgressBar';
import { Content } from '@components/Content';
import { FormData, Theme } from '@contexts';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';

import { TitleAndDescriptionField } from './TitleAndDescriptionField';
import useForm, { FormProps } from './useForm';
import { FormField } from './FormField';
import { ThankYou } from './ThankYou';
import classes from './Form.classes';

function Form({
  blockTheme,
  caption,
  captionImage,
  defaultValues,
  fieldIndicationType,
  id,
  indicator,
  name,
  nextLabel,
  notificationRecipients,
  pages,
  previousLabel,
  submitLabel,
  summary,
  thankYouCaption,
  thankYouSummary,
  thankYouTitle,
  title,
  customID,
}: FormProps): JSX.Element | null {
  const {
    currentPage,
    currentPageIndex,
    formDataValue,
    formState,
    hasMounted,
    hasSubmitted,
    isSubmitting,
    recaptchaRef,
    setHasMounted,
    showErrorMessage,
    showNextButton,
    showPreviousButton,
    showSubmitErrorMessage,
    showSubmitButton,
    getColumnsClass,
    isDirty,
    isInvalid,
    nextPage,
    onStartForm,
    onSubmit,
    previousPage,
    register,
    restart,
    theme,
  } = useForm({
    blockTheme,
    defaultValues,
    fieldIndicationType,
    indicator,
    name,
    notificationRecipients,
    pages,
    thankYouCaption,
    thankYouSummary,
    thankYouTitle,
  });

  useEffect(() => setHasMounted(true), [setHasMounted]);

  if (!hasMounted) return null;

  return (
    <Theme.Provider value={theme}>
      <FormData.Provider value={formDataValue}>
        <form className={classes.form} id={id} onSubmit={() => onSubmit()}>
          <ReCAPTCHA
            ref={recaptchaRef as LegacyRef<ReCAPTCHA>}
            size="invisible"
            sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
          />
          {!hasSubmitted && (
            <div id={customID}>
              <Content
                className={classes.content}
                caption={caption}
                captionImage={captionImage}
                title={title}
                richText={summary}
              />
              {pages.length > 1 && <ProgressBar length={pages.length} currentIndex={currentPageIndex} />}
              <div className={classes.page}>
                {currentPage.caption && <h3> {currentPage.caption} </h3>}
                <div className={classes.fieldsetsContainer}>
                  {currentPage.fieldsets.map((fieldset: FormFieldset) => (
                    <fieldset key={fieldset.id} className={classes.fieldset}>
                      {fieldset.caption && <legend className={classes.fieldsetLegend}>{fieldset.caption}</legend>}
                      <div className={cn(classes.columnsContainer, getColumnsClass(fieldset.columns.length))}>
                        {fieldset.columns.map((column: FormColumn) => (
                          <div key={column.id} className={classes.column}>
                            {column.caption && <h4>{column.caption}</h4>}
                            <div className={classes.fieldsContainer}>
                              {column.fields.map((field: FormFieldInterface) => (
                                <div key={field.id} className={classes.field}>
                                  {field.name === 'TitleAndDescription' ? (
                                    <TitleAndDescriptionField {...(field as TitleAndDescriptionFieldInterface)} />
                                  ) : (
                                    <FormField
                                      {...field}
                                      errors={formState.errors}
                                      isDirty={isDirty(field.alias)}
                                      isInvalid={isInvalid(field.alias)}
                                      register={register}
                                      onChange={() => onStartForm()}
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  ))}
                </div>
              </div>
              <div className={classes.buttons}>
                {showPreviousButton && (
                  <Button onClick={previousPage}>
                    <Icon icon="fas fa-arrow-left" className={classes.backButtonIcon} />
                    {previousLabel}
                  </Button>
                )}
                {showNextButton && (
                  <Button onClick={nextPage}>
                    {nextLabel}
                    <Icon icon="fas fa-arrow-right" className={classes.nextButtonIcon} />
                  </Button>
                )}
                {showSubmitButton && (
                  <Button onClick={() => !isSubmitting && onSubmit()}>
                    {isSubmitting ? 'Sending...' : submitLabel}
                  </Button>
                )}
                {showErrorMessage && !formState.isValid && (
                  <p className={classes.errorMessage}>
                    <Icon className={classes.errorIcon} icon="fas fa-exclamation-circle" />
                    Please check the form for errors.
                  </p>
                )}
                {showSubmitErrorMessage && (
                  <p className={classes.errorMessage}>
                    <Icon className={classes.errorIcon} icon="fas fa-exclamation-circle" />
                    Something went wrong, please contact us directly.
                  </p>
                )}
              </div>
            </div>
          )}
          {hasSubmitted && <ThankYou onRestart={restart} />}
        </form>
      </FormData.Provider>
    </Theme.Provider>
  );
}

export default Form;
