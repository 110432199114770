import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { TextareaHTMLAttributes } from 'react';
import cn from 'classnames';

import { LongAnswerField as LongAnswerFieldInterface } from '@interfaces/forms';

import classes from './LongAnswerField.classes';

export interface LongAnswerFieldProps extends LongAnswerFieldInterface {
  isDirty: boolean;
  isInvalid: boolean;
  register: UseFormRegister<FieldValues>;
  registerOptions: RegisterOptions;
}

function LongAnswerField({
  alias,
  autocompleteAttribute,
  id,
  isDirty,
  isInvalid,
  maximumLength,
  numberOfRows,
  placeholder,
  register,
  registerOptions,
}: LongAnswerFieldProps): JSX.Element {
  const attributes: TextareaHTMLAttributes<any> = { id, rows: numberOfRows || 5 };
  const textareaClasses = cn(classes.textarea, { [classes.invalid]: isInvalid && isDirty });
  const regOptions = { ...registerOptions, maxLength: maximumLength };

  if (autocompleteAttribute) attributes.autoComplete = autocompleteAttribute;
  if (maximumLength) attributes.maxLength = maximumLength;
  if (placeholder) attributes.placeholder = placeholder;

  return <textarea className={textareaClasses} {...attributes} {...register(alias, regOptions)} />;
}

export default LongAnswerField;
