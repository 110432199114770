import NextLink from 'next/link';
import { BlogTag, BlogTagList as BlogTagListInterface } from '@interfaces';
import { Content } from '@components/Content';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './BlogTagList.classes';

export interface BlogTagListProps extends BlogTagListInterface {}

function BlogTagList({
  caption,
  captionImage,
  title,
  tags,
  blockTheme,
  blockIdentifier,
}: BlogTagListProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  return (
    <Theme.Provider value={theme}>
      <div className={classes.componentWrapper}>
        <Content caption={caption} captionImage={captionImage} title={title} />
        <div className={classes.listContainer}>
          {tags.map((tag: BlogTag) => (
            <NextLink
              key={tag.key}
              href={tag.url}
              className={classes.pill}
              data-element-click={`link[${tag.name}]_${blockIdentifier}`}
            >
              {tag.name} ({tag.totalPosts})
            </NextLink>
          ))}
        </div>
      </div>
    </Theme.Provider>
  );
}

export default BlogTagList;
