import cn from 'classnames';

import { Accordion as AccordionInterface, AccordionItem } from '@interfaces';
import { ButtonProps } from '@components/Button';
import { LinkProps } from '@components/Link';
import { RichTextContent } from '@components/RichTextContent';
import { Content } from '@components/Content';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './Accordion.classes';

export interface AccordionProps extends AccordionInterface {}

function Accordion({
  caption,
  captionImage,
  title,
  items,
  blockTheme,
  blockIdentifier,
  customID,
  button,
  link,
}: AccordionProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }

  return (
    <Theme.Provider value={theme}>
      <div className={classes.componentWrapper} id={customID}>
        <Content
          caption={caption}
          captionImage={captionImage}
          title={title}
          buttonProps={buttonProps}
          linkProps={linkProps}
        />
        <div className={classes.items}>
          {items.map((item: AccordionItem) => (
            <details className={classes.item} key={item.key}>
              <summary className={cn(classes.itemTitle, theme.marker)}>{item.title}</summary>
              {item.summary && (
                <div className={classes.itemSummary}>
                  <RichTextContent content={item.summary} />
                </div>
              )}
            </details>
          ))}
        </div>
      </div>
    </Theme.Provider>
  );
}

export default Accordion;
