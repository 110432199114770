import { ClassesBuilder } from '@utilities';

const classesConfig = {
  container: {
    default: 'rounded-xl shadow-lg bg-white p-6',
    md: 'md:p-8',
  },
  content: {
    default: 'text-click-para text-sm mb-4',
    md: 'md:text-base',
  },
  stars: 'text-lg',
  author: 'font-semibold text-click-blue not-italic',
};

export default new ClassesBuilder(classesConfig);
