import React from 'react';
import { Button } from '@components/Button';

import { ComparisonList as ComparisonListProps } from '@interfaces';
import { Icon } from '@components/Icon';
import { Theme } from '@contexts';
import cn from 'classnames';
import { Content } from '@components/Content';
import { RichTextContent } from '@components/RichTextContent';
import { getBorder } from '@utilities';

import classes from './ComparisonList.classes';

import useComparisonList from './useComparisonList';

function ComparisonList({ items, blockTheme, caption, title, columns }: ComparisonListProps): JSX.Element {
  const { gridColumns, theme } = useComparisonList({ columns, blockTheme });
  const border = getBorder(theme.name);

  return (
    <Theme.Provider value={theme}>
      <div className={cn(classes.contentContainer)}>
        <Content caption={caption} title={title} />
      </div>
      <div className={cn(classes.grid, gridColumns)}>
        {items.map((item, index) => (
          <div key={index} className={cn(classes.comparisonItem, border)}>
            <h3 className={classes.heading}>{item.title}</h3>
            <div className={classes.description}>
              <RichTextContent content={item.description} />
            </div>
            {item.price && <div className={classes.price}>{item.price}</div>}
            {item.button && (
              <div className={classes.button}>
                <Button {...item.button}>{item.button.name}</Button>
              </div>
            )}
            {item.featureList && (
              <ul className={classes.featureList}>
                {item.featureList.map((feature, featureIndex) => (
                  <li key={`${index}-${featureIndex}`} className={classes.featureItem}>
                    <Icon icon="fa fa-check" className={cn(classes.featureIcon, theme.background)} />
                    {feature.feature && <RichTextContent content={feature.feature} />}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </Theme.Provider>
  );
}

export default ComparisonList;
