import cn from 'classnames';

import { IconPromotionItem } from '@interfaces';
import { ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { Icon } from '@components/Icon';
import { Link, LinkProps } from '@components/Link';
import { RichTextContent } from '@components/RichTextContent';
import { Theme } from '@contexts';

import classes from './IconPromotion.classes';

import useIconPromotion, { IconPromotionProps } from './useIconPromotion';

function IconPromotion({
  caption,
  captionImage,
  title,
  items,
  blockTheme,
  blockIdentifier,
  columns,
  customID,
  button,
  link,
}: IconPromotionProps): JSX.Element {
  const { gridColumns, theme } = useIconPromotion({ columns, blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }

  return (
    <Theme.Provider value={theme}>
      <div id={customID}>
        <Content
          className={classes.contentContainer}
          caption={caption}
          captionImage={captionImage}
          title={title}
          buttonProps={buttonProps}
          linkProps={linkProps}
        />
        <div className={cn(classes.grid, gridColumns)}>
          {items.map((item: IconPromotionItem) => (
            <div className={classes.item} key={item.key}>
              {item.icon && (
                <div className={classes.iconContainer}>
                  <Icon className={cn(classes.icon, theme.color)} icon={item.icon} />
                </div>
              )}
              <h3 className={classes.title}>{item.title}</h3>
              <div className={classes.summary}>
                <RichTextContent content={item.summary} />
              </div>
              {item.link && (
                <Link
                  {...item.link}
                  icon="fas fa-arrow-right"
                  elementClick={`link[${item.link?.name}]_${blockIdentifier}`}
                >
                  {item.link.name}
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </Theme.Provider>
  );
}

export default IconPromotion;
