import { ClassesBuilder } from '@utilities';

const classesConfig = {
  heroUnit: {
    default:
      '-mt-[6.5rem] pt-[4rem] px-6 overflow-hidden bg-gradient-to-b from-white to-click-grey flex flex-col gap-8',
    '2xs': '2xs:-mt-[7rem] 2xs:pt-[5rem]',
    sm: 'sm:gap-8',
    md: 'md:-mt-[8.5rem] md:pt-[6rem] md:px-8 md:gap-10',
  },
  container: 'container mx-auto',
  contentContainer: {
    default: 'flex flex-col items-center mt-6',
    sm: 'sm:mt-8',
    md: 'md:mt-10',
    lg: 'lg:mt-12',
  },
  caption: {
    default: 'text-base font-bold mb-2',
    sm: 'sm:mb-3',
  },
  captionImageContainer: {
    default: 'relative w-full h-6 -mb-2',
    md: 'md:h-8',
    xl: 'xl:h-10',
  },
  captionImage: 'w-auto h-full',
  title: {
    default: 'mb-2 text-3xl font-bold text-center text-click-blue',
    sm: 'sm:mb-3',
    md: 'md:text-4xl',
    lg: 'lg:text-5xl',
  },
  summary: {
    default: 'mb-4 text-base font-normal text-center text-blue',
    md: 'md:text-lg',
    lg: 'lg:text-xl',
  },
  actionsContainer: {
    default: 'flex flex-col items-center justify-center items-start',
    sm: 'sm:space-x-8 sm:flex-row',
  },
  imageContainer: 'flex justify-center',
  desktopImageContainer: {
    default: 'w-full max-w-screen-xl mx-auto hidden relative',
    sm: 'sm:block',
  },
  desktopImage: 'w-full h-auto',
  mobileImageContainer: {
    default: 'w-[80%] max-h-[350px]',
    sm: 'sm:hidden',
  },
  mobileImage: 'w-full h-auto',
};

export default new ClassesBuilder(classesConfig);
