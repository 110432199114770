import { v4 as uuidv4 } from 'uuid';
import { useMemo } from 'react';
import cn from 'classnames';

import { useTheme } from '@utilities';
import { Theme } from '@interfaces';

import classes from './ProgressBar.classes';

export interface ProgressBarProps {
  length: number;
  currentIndex: number;
  blockTheme?: Theme;
}

function ProgressBar({ length, currentIndex, blockTheme }: ProgressBarProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  const items = useMemo(() => Array.from(Array(length).keys()).map(() => uuidv4()), [length]);

  return (
    <div className={classes.progressBar}>
      {items.map((key: string, index: number) => (
        <div key={key} className={cn(classes.item, index <= currentIndex ? theme.background : classes.inactiveItem)} />
      ))}
    </div>
  );
}

export default ProgressBar;
