import NextImage from 'next/image';
import NextLink from 'next/link';
import cn from 'classnames';

import { FromNow } from '@components/FromNow';
import { Image, Theme } from '@interfaces';
import { Icon } from '@components/Icon';

import classes from './BlogPostCard.classes';

export interface BlogPostCardProps {
  date: string;
  elementClick?: string;
  heading: string;
  image: Image;
  theme: Theme;
  url: string;
}

function BlogPostCard({ date, elementClick, heading, image, theme, url }: BlogPostCardProps): JSX.Element {
  return (
    <NextLink className={classes.card} href={url} data-element-click={elementClick || null}>
      <div className={cn(classes.blogPost, theme.backgroundHoverAlt)}>
        <div className={classes.blogPostImageContainer}>
          <NextImage
            src={image.src}
            alt={image.alt || ''}
            width={image.width}
            height={image.height}
            sizes="100vw"
            quality={75}
            className={classes.blogPostImage}
          />
        </div>
        <div className={classes.blogPostDetailsContainer}>
          <div className={cn(classes.blogPostDate, theme.color)}>
            <Icon icon="fa fa-clock" className="mr-1" />
            <FromNow date={date} />
          </div>
          <div className={classes.blogPostHeading}>{heading}</div>
        </div>
      </div>
    </NextLink>
  );
}

export default BlogPostCard;
