import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { SelectHTMLAttributes } from 'react';
import cn from 'classnames';

import { DropdownField as DropdownFieldInterface, FormPreValue } from '@interfaces/forms';

import classes from './DropdownField.classes';

export interface DropdownFieldProps extends DropdownFieldInterface {
  isDirty: boolean;
  isInvalid: boolean;
  register: UseFormRegister<FieldValues>;
  registerOptions: RegisterOptions;
}

function DropdownField({
  alias,
  allowMultipleSelections,
  autocompleteAttribute,
  defaultValue,
  id,
  isDirty = false,
  isInvalid = false,
  preValues,
  register,
  registerOptions,
  selectPrompt,
}: DropdownFieldProps): JSX.Element {
  const attributes: SelectHTMLAttributes<any> = { id, defaultValue: defaultValue || '' };
  const selectClasses = cn(classes.select, { [classes.invalid]: isInvalid && isDirty });

  if (autocompleteAttribute) attributes.autoComplete = autocompleteAttribute;
  if (allowMultipleSelections) attributes.multiple = allowMultipleSelections;

  return (
    <select className={selectClasses} {...attributes} {...register(alias, registerOptions)}>
      {selectPrompt && <option value="">{selectPrompt}</option>}
      {preValues.map((preValue: FormPreValue) => (
        <option key={preValue.value} value={preValue.value}>
          {preValue.caption || preValue.value}
        </option>
      ))}
    </select>
  );
}

export default DropdownField;
