import NextImage from 'next/image';

import { ImageOnly as ImageOnlyInterface } from '@interfaces';
import { ButtonProps } from '@components/Button';
import { LinkProps } from '@components/Link';
import { Content } from '@components/Content';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './ImageOnly.classes';

export interface ImageOnlyProps extends ImageOnlyInterface {}

function ImageOnly({ image, blockTheme, blockIdentifier, button, link }: ImageOnlyProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }

  return (
    <Theme.Provider value={theme}>
      {(buttonProps || linkProps) && (
        <Content className={classes.contentContainer} buttonProps={buttonProps} linkProps={linkProps} />
      )}
      <div className={classes.imageContainer}>
        <NextImage
          src={image.src}
          alt={image.alt || ''}
          width={image.width}
          height={image.height}
          sizes="100vw"
          quality={75}
          className={classes.image}
        />
      </div>
    </Theme.Provider>
  );
}

export default ImageOnly;
