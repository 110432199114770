import cn from 'classnames';

import { PageSection as PageSectionInterface, PageSectionArea } from '@interfaces';
import { BlockList } from '@components/BlockList';
import { DarkBackground } from '@contexts';

import classes from './PageSection.classes';

export interface PageSectionProps extends PageSectionInterface {}

function PageSection({ areas, hasDarkBackground, settings, children }: PageSectionProps): JSX.Element {
  const pageSection = (
    <section className={cn(classes.section, settings.spacingTop, settings.spacingBottom, settings.backgroundColor)}>
      <div className={classes.container}>
        {!!children && children}
        {!children && (
          <div className={classes.grid}>
            {areas.map((area: PageSectionArea) => (
              <div key={area.key} className={cn(classes.area, area.columnClasses)}>
                {area.blocks && area.blocks.length > 0 && <BlockList blocks={area.blocks} />}
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );

  if (hasDarkBackground === true) return <DarkBackground.Provider value>{pageSection}</DarkBackground.Provider>;

  return pageSection;
}

export default PageSection;
