import { ClassesBuilder } from '@utilities';

const classesConfig = {
  heroUnit: {
    default:
      '-mt-[6.5rem] pt-[4rem] overflow-hidden flex items-center justify-center bg-gradient-to-b from-white to-click-grey',
    '2xs': '2xs:-mt-[7rem] 2xs:pt-[5rem]',
    md: 'md:-mt-[8.5rem] md:pt-[6rem]',
  },
  container: {
    default: 'w-full mx-auto grid gap-4',
    md: 'md:gap-2',
    lg: 'lg:grid-cols-1',
    xl: 'xl:grid-cols-2 xl:container',
  },
  contentContainer: {
    default: 'py-4 px-6',
    xs: 'xs:pb-0',
    md: 'md:py-8 md:pb-20 md:px-8',
    lg: 'lg:py-16 lg:pb-20',
  },
  caption: {
    default: 'text-base font-bold mb-2 text-click-orange',
  },
  captionImageContainer: {
    default: 'relative w-full h-6 -mb-2',
    md: 'md:h-8',
    xl: 'xl:h-10',
  },
  captionImage: 'w-auto h-full',
  title: {
    default: 'mb-2 text-3xl font-bold text-click-blue',
    sm: 'sm:mb-4',
    xl: 'xl:text-4xl',
  },
  summary: {
    default: 'mb-6 text-base text-click-text',
    xl: 'xl:text-lg',
  },
  actionsContainer: {
    default: 'flex flex-col gap-4 justify-start items-start',
    sm: 'sm:flex-row sm:items-center',
  },
  imageContainer: 'relative flex items-end justify-stretch mb-[1.5rem]',
  desktopImageContainer: {
    default: 'relative w-full hidden z-10',
    lg: 'lg:block',
  },
  desktopImage: 'w-full h-auto',
  mobileImageContainer: {
    default: 'relative w-full block z-10',
    lg: 'lg:hidden',
  },
  mobileImage: 'w-full h-auto',
};

export default new ClassesBuilder(classesConfig);
