import { ClassesBuilder } from '@utilities';

const classesConfig = {
  checkbox: {
    default: 'flex items-center py-2 text-sm bg-white text-click-text relative',
  },
  input: 'appearance-none shrink-0 w-6 h-6 mr-3 border border-gray-300 rounded-md transition',
  tick: 'pointer-events-none absolute text-white text-lg w-6 h-6 block ml-[3px] -mt-[2px]',
  label: 'text-sm font-normal text-click-text',
  invalid: 'border-red-500 focus:border-red-500',
};

export default new ClassesBuilder(classesConfig);
