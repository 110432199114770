import { ClassesBuilder } from '@utilities';

const classesConfig = {
  contentContainer: {
    default: 'mb-4',
    md: 'md:mb-6',
  },
  grid: {
    default: 'grid gap-4 grid-cols-1',
    md: 'md:gap-y-6 md:gap-x-8',
  },
  item: {
    default: 'p-4 bg-white shadow-lg rounded-xl transition hover:scale-105 active:scale-95 border',
    sm: 'sm:p-6',
  },
  step: {
    default: 'text-white text-xl font-semibold rounded-full h-10 w-10 flex justify-center items-center mb-2',
    sm: 'sm:mb-3',
  },
  title: {
    default: 'text-lg font-bold text-click-blue mb-2',
    sm: 'sm:mb-3',
  },
  summary: {
    default: 'text-base text-click-text mb-2',
    sm: 'sm:mb-3',
  },
};

export default new ClassesBuilder(classesConfig);
