import { Address as AddressInterface } from '@interfaces';
import { Content } from '@components/Content';
import { ButtonProps } from '@components/Button';
import { LinkProps } from '@components/Link';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './Address.classes';

export interface AddressProps extends AddressInterface {}

function AddressComponent({
  caption,
  captionImage,
  title,
  companyName,
  line1,
  line2,
  town,
  county,
  postcode,
  blockTheme,
  blockIdentifier,
  customID,
  button,
  link,
}: AddressProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }

  return (
    <Theme.Provider value={theme}>
      <div id={customID}>
        <Content
          className={classes.contentContainer}
          caption={caption}
          captionImage={captionImage}
          title={title}
          buttonProps={buttonProps}
          linkProps={linkProps}
        />
        <address className={classes.address}>
          {companyName && (
            <>
              <span>{companyName},</span>
              <br />
            </>
          )}
          <span>{line1},</span>
          <br />
          {line2 && (
            <>
              <span>{line2},</span>
              <br />
            </>
          )}
          <span>{town},</span>
          <br />
          {county && (
            <>
              <span>{county},</span>
              <br />
            </>
          )}
          <span>{postcode}</span>
        </address>
      </div>
    </Theme.Provider>
  );
}

export default AddressComponent;
