import { ClassesBuilder } from '@utilities';

const className = {
  componentWrapper: {
    default: 'grid gap-4 bg-transparent',
  },
  title: {
    default: 'text-2xl font-bold text-click-blue mb-4',
    lg: 'lg:text-3xl',
  },
  items: {
    default: 'grid gap-4',
  },
  item: {
    default: 'hover:bg-gray-200 transition rounded-lg bg-click-blue bg-opacity-[7%]',
  },
  itemTitle: {
    default: 'p-4 font-bold text-base text-click-blue cursor-pointer',
    marker: 'marker:text-base',
    md: 'md:p-6 md:text-lg',
  },
  itemSummary: {
    default: '-mt-4 p-4 text-base text-click-para',
    md: 'md:-mt-6 md:p-6',
  },
};

export default new ClassesBuilder(className);
