import { ClassesBuilder } from '@utilities';

const classesConfig = {
  labelledList: {
    default: 'copy rounded-xl shadow-xl p-6 mb-sm',
    md: 'md:p-8',
  },
  table: 'table-fixed',
  tr: {
    default: 'flex flex-col py-3 border-b border-gray-200 border-dashed last-of-type:border-none',
    md: 'md:flex-row',
  },
  label: {
    default: 'w-full font-bold text-click-blue',
    md: 'md:w-40',
  },
  content: {
    default: 'w-4/5 text-click-text space-y-2',
    sm: 'sm:space-y-3',
  },
  summary: {
    default: 'text-base text-click-text',
  },
  title: {
    default: 'text-base font-bold text-click-blue mb-0',
  },
};

export default new ClassesBuilder(classesConfig);
