import { ClassesBuilder } from '@utilities';

const classesConfig = {
  pageMain: {
    default: 'pt-[6.5rem]',
    '2xs': '2xs:pt-[7rem]',
    md: 'md:pt-[8.5rem]',
  },
  pageContent: {
    default: 'bg-white rounded-3xl  -my-6 relative z-10 overflow-hidden shadow-[0_0_24px_rgba(0,0,0,0.08)] flex',
  },
  blogArea: 'container mx-auto',
  blogSidebar: '2xs:w-full 2xs:px-6 2xs:mt-0 2xs:mb-16 md:mt-16 md:w-1/3 md:p-0 ',
  blogContainer: 'container mx-auto flex gap-12 2xs:gap-0 2xs:flex-wrap md:gap-12 md:flex-nowrap',
};

export default new ClassesBuilder(classesConfig);
