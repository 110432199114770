import { useCallback } from 'react';

import { ComparisonList as ComparisonListInterface } from '@interfaces';
import { useTheme } from '@utilities';

export interface ComparisonListProps extends ComparisonListInterface {}

function useComparisonList({ columns, blockTheme }: Partial<ComparisonListProps>) {
  const theme = useTheme({ blockTheme });

  const getGridColumns = useCallback((): string => {
    if (columns === 2) return 'md:grid-cols-2';
    if (columns === 3) return 'lg:grid-cols-3';
    if (columns === 4) return 'md:grid-cols-2 xl:grid-cols-4';
    return '';
  }, [columns]);

  const gridColumns = getGridColumns();

  return {
    theme,
    gridColumns,
  };
}

export default useComparisonList;
