import { ClassesBuilder } from '@utilities';

const classesConfig = {
  contentContainer: {
    default: 'flex flex-col gap-2',
    sm: 'sm:gap-6',
  },
  textContainer: {
    default: 'flex flex-col gap-2',
  },
  caption: {
    default: 'text-sm font-bold',
    sm: 'sm:text-base',
  },
  captionImageContainer: {
    default: 'relative w-full h-6 mb-2',
    md: 'md:h-8',
    xl: 'xl:h-10',
  },
  captionImage: 'w-auto h-full',
  title: {
    default: 'text-2xl font-bold mb-0',
    lg: 'lg:text-3xl',
  },
  summary: {
    default: 'text-base last-of-type:mb-0',
  },
  actionsContainer: {
    default: 'flex flex-col gap-4 justify-start items-start',
    xs: 'xs:flex-row xs:items-center xs:gap-6',
    md: 'md:-mt-2',
  },
};

export default new ClassesBuilder(classesConfig);
