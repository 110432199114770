import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { InputHTMLAttributes } from 'react';
import cn from 'classnames';

import { CheckboxField as CheckboxFieldInterface } from '@interfaces/forms';
import { Icon } from '@components/Icon';
import { useTheme } from '@utilities';

import classes from './CheckboxField.classes';

export interface CheckboxFieldProps extends CheckboxFieldInterface {
  isDirty: boolean;
  isInvalid: boolean;
  register: UseFormRegister<FieldValues>;
  registerOptions: RegisterOptions;
}

function CheckboxField({
  id,
  alias,
  caption,
  isDirty,
  isInvalid,
  register,
  registerOptions,
}: CheckboxFieldProps): JSX.Element {
  const theme = useTheme({});

  const attributes: InputHTMLAttributes<any> = { id, type: 'checkbox' };
  const inputClasses = cn(classes.input, theme.checkbox, { [classes.invalid]: isInvalid && isDirty });

  return (
    <div className={classes.checkbox}>
      <input className={inputClasses} {...attributes} {...register(alias, registerOptions)} />
      <Icon className={classes.tick} icon="fas fa-check" />
      <label htmlFor={id} className={classes.label}>
        {caption}
      </label>
    </div>
  );
}

export default CheckboxField;
