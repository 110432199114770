import { ClassesBuilder } from '@utilities';

const classesConfig = {
  blogPost: {
    default: 'h-full rounded-lg shadow-lg group bg-white transition overflow-hidden w-full snap-center',
  },
  blogPostImageContainer: 'relative aspect-[1110/550] overflow-hidden',
  blogPostImage: 'absolute w-full h-full object-cover transition group-hover:scale-110',
  blogPostDetailsContainer: {
    default: 'p-4',
    sm: 'sm:p-5',
    lg: 'lg:p-6',
  },
  blogPostDate: 'text-sm font-semibold group-hover:text-click-blue mb-1 transition',
  blogPostHeading: {
    default: 'text-lg leading-snug font-semibold text-click-blue group-hover:text-white transition',
    lg: 'lg:text-xl',
  },
};

export default new ClassesBuilder(classesConfig);
