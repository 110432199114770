import { ClassesBuilder } from '@utilities';

const classesConfig = {
  item: 'transition hover:scale-105',
  contentContainer: {
    default: 'mb-4',
  },
  grid: {
    default: 'grid gap-4 grid-cols-1',
    md: 'md:gap-y-6 md:gap-x-8',
  },
  iconContainer: 'w-10 h-10 text-[40px] mb-6',
  title: {
    default: 'text-lg font-bold text-click-blue mb-2',
    sm: 'sm:mb-3',
  },
  summary: {
    default: 'text-base text-click-text mb-2',
    sm: 'sm:mb-3',
  },
};

export default new ClassesBuilder(classesConfig);
