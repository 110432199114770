import NextLink from 'next/link';
import cn from 'classnames';

import { Icon } from '@components/Icon';
import { useTheme } from '@utilities';

import classes from './Breadcrumbs.classes';

export interface BreadcrumbItem {
  name: string;
  url?: string;
}

export interface BreadcrumbsProps {
  items: BreadcrumbItem[];
}

function Breadcrumbs({ items }: BreadcrumbsProps): JSX.Element | null {
  const theme = useTheme({});
  if (!items || items.length < 1) return null;

  return (
    <nav className={classes.nav} aria-label="Breadcrumb">
      <ol className={classes.ol}>
        {items.map((item: BreadcrumbItem, index: number) => {
          let breadcrumbItem: JSX.Element = <div>{item.name}</div>;

          if (item.url) {
            breadcrumbItem = (
              <NextLink href={item.url} className={cn(classes.link, theme.color)}>
                {breadcrumbItem}
              </NextLink>
            );
          }

          if (!item.url) {
            breadcrumbItem = (
              <div aria-current="page" className={classes.current}>
                {breadcrumbItem}
              </div>
            );
          }

          return (
            <li key={`${item.name.replaceAll(' ', '-')}_${index}`} className={classes.li}>
              {breadcrumbItem}
              {index !== items.length - 1 && (
                <div className={classes.iconContainer}>
                  <Icon icon="fas fa-chevron-right" className={cn(classes.icon, theme.color)} />
                </div>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;
