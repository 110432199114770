import { Map as MapInterface } from '@interfaces';

import classes from './Map.classes';

export interface MapProps extends MapInterface {}

function MapComponent({ mapSrc, customID }: MapProps): JSX.Element {
  return (
    <div className={classes.mapContainer} id={customID}>
      <iframe
        src={mapSrc}
        width="100%"
        height="100%"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Map"
      />
    </div>
  );
}
export default MapComponent;
