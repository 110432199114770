import { ClassesBuilder } from '@utilities';

const classesConfig = {
  container: {
    default:
      'block w-full bg-white text-sm text-click-text border border-gray-300 rounded placeholder:text-gray-400 focus:outline-none focus:bg-white focus:border-blue-500 invalid:border-red-500 overflow-hidden',
  },
  invalid: 'border-red-500 focus:border-red-500',
  item: {
    default:
      'group flex items-stretch border border-0 border-b border-dotted border-gray-300 last:border-b-0 hover:bg-click-grey relative ',
  },
  input: {
    default:
      'm-3 absolute top-[2px] bg-white pointer-events-none appearance-none rounded-full w-4 h-4 border border-gray-300 checked:border-2 checked:outline checked:outline-2 checked:outline-offset-2 checked:w-2 checked:h-2 checked:m-4',
    lg: 'lg:top-[2px] lg:m-3 lg:checked:m-4',
  },
  label: {
    default: 'block grow cursor-pointer m-0 p-3 pl-10',
    lg: 'lg:pl-10',
  },
};

export default new ClassesBuilder(classesConfig);
