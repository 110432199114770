import { ButtonRounded as ButtonRoundedInterface } from '@interfaces';

export interface ButtonRoundedProps extends ButtonRoundedInterface {}

function ButtonRounded({ className, button }: ButtonRoundedProps): JSX.Element {
  return (
    <button className={className} type="button">
      {button}
    </button>
  );
}

export default ButtonRounded;
