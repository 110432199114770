import { ClassesBuilder } from '@utilities';

const classesConfig = {
  container: {
    default: 'grid grid-cols-1 gap-4',
    md: 'md:grid-cols-2 md:gap-6',
  },
};

export default new ClassesBuilder(classesConfig);
