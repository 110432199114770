import { LabelledList as LabelledListInterface, LabelledListItem } from '@interfaces';
import { RichTextContent } from '@components/RichTextContent';
import { ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { LinkProps } from '@components/Link';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './LabelledList.classes';

export interface LabelledListProps extends LabelledListInterface {}

function LabelledList({
  caption,
  captionImage,
  title,
  summary,
  button,
  link,
  items,
  blockTheme,
  blockIdentifier,
  customID,
}: LabelledListProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }

  return (
    <Theme.Provider value={theme}>
      <div className={classes.labelledList} id={customID}>
        <Content
          className={classes.contentContainer}
          caption={caption}
          captionImage={captionImage}
          title={title}
          richText={summary}
          buttonProps={buttonProps}
          linkProps={linkProps}
        />
        <table className={classes.table}>
          <tbody>
            {items.map((item: LabelledListItem) => (
              <tr key={item.key} className={classes.tr}>
                <td className={classes.label}>{item.label}</td>
                <td className={classes.content}>
                  {item.title && <h3 className={classes.title}>{item.title}</h3>}
                  {item.summary && (
                    <div className={classes.summary}>
                      <RichTextContent content={item.summary} />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Theme.Provider>
  );
}

export default LabelledList;
