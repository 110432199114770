import { useCallback } from 'react';

import { SteppedPromotion as SteppedPromotionInterface } from '@interfaces';
import { useTheme } from '@utilities';

export interface SteppedPromotionProps extends SteppedPromotionInterface {}

function useSteppedPromotion({ columns, blockTheme }: Partial<SteppedPromotionProps>) {
  const theme = useTheme({ blockTheme });

  const getGridColumns = useCallback((): string => {
    if (columns === 2) return 'md:grid-cols-2';
    if (columns === 3) return 'lg:grid-cols-3';
    if (columns === 4) return 'md:grid-cols-2 lg:grid-cols-4';
    return '';
  }, [columns]);

  const gridColumns = getGridColumns();

  return {
    theme,
    gridColumns,
  };
}

export default useSteppedPromotion;
