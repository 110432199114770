import { ClassesBuilder } from '@utilities';

const classesConfig = {
  container: {
    default: 'container mx-auto px-4 mt-28',
  },
  header: {
    default: 'flex items-center mb-6',
  },
  title: {
    default: 'text-3xl font-bold mb-0 w-2/3',
  },
  selectWrapper: {
    default: 'flex items-center justify-end',
  },
  select: {
    default: 'border rounded pr-2 pl-1 py-2',
  },
  grid: {
    default: 'grid grid-cols-1 gap-6',
    md: 'md:grid-cols-2',
    lg: 'lg:grid-cols-3',
  },
  pagination: {
    default: 'flex justify-between items-center mt-8 mb-4',
  },
  paginationInner: {
    default: 'flex justify-center w-full space-x-1',
  },
  paginationButton: {
    default:
      'flex bg-transparent text-gray-700 hover:bg-gray-100 min-h-[38px] min-w-[38px] py-2 px-2.5 justify-center items-center rounded gap-x-1.5 text-sm disabled:opacity-40 disabled:hover:bg-transparent',
  },
  paginationButtonActive: {
    default: 'bg-click-orange text-white rounded min-h-[38px] min-w-[38px]',
  },
  paginationButtonInactive: {
    default: 'bg-transparent text-gray-700 hover:bg-gray-100 rounded min-h-[38px] min-w-[38px]',
  },
  paginationEllipsis: {
    default: 'px-3 py-1 min-h-[38px] bg-transparent text-click-text',
  },
};

export default new ClassesBuilder(classesConfig);
