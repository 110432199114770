import cn from 'classnames';

import { RichTextBlock as RichTextBlockInterface } from '@interfaces';
import { ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { LinkProps } from '@components/Link';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

export interface RichTextBlockProps extends RichTextBlockInterface {}

function RichTextBlock({
  caption,
  captionImage,
  title,
  richText,
  button,
  link,
  blockTheme,
  blockIdentifier,
  fillBackground,
  customID,
}: RichTextBlockProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }
  return (
    <Theme.Provider value={theme}>
      <div
        id={customID}
        className={cn(
          '',
          fillBackground
            ? `relative p-4 before:content-[''] before:absolute before:top-0 before:left-0 before:h-full before:w-full before:opacity-10 before:${theme.background}`
            : '',
        )}
      >
        <Content
          caption={caption}
          captionImage={captionImage}
          title={title}
          richText={richText}
          buttonProps={buttonProps}
          linkProps={linkProps}
        />
      </div>
    </Theme.Provider>
  );
}

export default RichTextBlock;
