import { ClassesBuilder } from '@utilities';

const classesConfig = {
  form: 'rounded-xl shadow-xl p-6 md:p-8',
  content: {
    default: 'mb-4',
    sm: 'sm:mb-6',
  },
  stepNumber: 'mr-2',
  fieldsetsContainer: 'grid gap-4',
  fieldset: 'grid gap-4 relative',
  fieldsetLegend: 'text-xl font-semibold text-click-blue mb-2',
  columnsContainer: {
    default: 'grid gap-4',
    md: 'md:gap-x-5',
  },
  column: {
    default: 'max-w-full',
    lg: 'lg:max-w-2xl',
  },
  fieldsContainer: 'grid gap-4',
  buttons: 'flex gap-4 mt-6 items-center',
  backButtonIcon: 'mr-2',
  nextButtonIcon: 'ml-2',
  errorMessage: 'm-0 text-red-500 p-0 ',
  errorIcon: 'mr-3',
};

export default new ClassesBuilder(classesConfig);
