import NextImage from 'next/image';
import cn from 'classnames';

import { TwoColumnRichTextBlock as TwoColumnRichTextBlockInterface } from '@interfaces';
import { RichTextContent } from '@components/RichTextContent';
import { ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { LinkProps } from '@components/Link';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './TwoColumnRichTextBlock.classes';

export interface TwoColumnRichTextBlockProps extends TwoColumnRichTextBlockInterface {}

function TwoColumnRichTextBlock({
  leftCaption,
  leftCaptionImage,
  leftTitle,
  leftRichText,
  rightCaption,
  rightCaptionImage,
  rightTitle,
  rightRichText,
  button,
  link,
  blockTheme,
  blockIdentifier,
  customID,
}: TwoColumnRichTextBlockProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }
  return (
    <Theme.Provider value={theme}>
      <div>
        {(leftCaption || leftCaptionImage || leftTitle || rightCaption || rightCaptionImage || rightTitle) && (
          <div className={classes.titleGrid} id={customID}>
            <div className={classes.textContainer}>
              {leftCaption && <div className={cn(classes.caption, theme.color)}>{leftCaption}</div>}
              {leftCaptionImage && (
                <div className={classes.captionImageContainer}>
                  <NextImage
                    src={leftCaptionImage.src}
                    alt={leftCaptionImage.alt || ''}
                    width={leftCaptionImage.width}
                    height={leftCaptionImage.height}
                    sizes="100vw"
                    quality={75}
                    className={classes.captionImage}
                  />
                </div>
              )}
              {leftTitle && <h2 className={classes.title}>{leftTitle}</h2>}
            </div>
            <div className={classes.textContainer}>
              {rightCaption && <h2 className={cn(classes.caption, theme.color)}>{rightCaption}</h2>}
              {rightCaptionImage && (
                <div className={classes.captionImageContainer}>
                  <NextImage
                    src={rightCaptionImage.src}
                    alt={rightCaptionImage.alt || ''}
                    width={rightCaptionImage.width}
                    height={rightCaptionImage.height}
                    sizes="100vw"
                    quality={75}
                    className={classes.captionImage}
                  />
                </div>
              )}
              {rightTitle && <h2 className={classes.title}>{rightTitle}</h2>}
            </div>
          </div>
        )}
        <div className={classes.contentGrid}>
          <div>
            {(leftCaption || leftTitle) && (
              <div className={classes.textContainer}>
                {leftCaption && (
                  <div className={cn(classes.caption, classes.inlineCaption, theme.color)}>{leftCaption}</div>
                )}
                {leftTitle && <h2 className={cn(classes.title, classes.inlineTitle)}>{leftTitle}</h2>}
              </div>
            )}
            <RichTextContent content={leftRichText} />
          </div>
          <div>
            {(rightCaption || rightTitle) && (
              <div className={classes.textContainer}>
                {rightCaption && (
                  <div className={cn(classes.caption, classes.inlineCaption, theme.color)}>{rightCaption}</div>
                )}
                {rightTitle && <h2 className={cn(classes.title, classes.inlineTitle)}>{rightTitle}</h2>}
              </div>
            )}
            <RichTextContent content={rightRichText} />
          </div>
        </div>
        <div className={classes.actionsContainer}>
          <Content buttonProps={buttonProps} linkProps={linkProps} />
        </div>
      </div>
    </Theme.Provider>
  );
}

export default TwoColumnRichTextBlock;
