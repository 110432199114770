import cn from 'classnames';

import { Review as ReviewInterface } from '@interfaces';
import { Icon } from '@components/Icon';
import { RichTextContent } from '@components/RichTextContent';
import { useTheme } from '@utilities';

import classes from './ReviewCard.classes';

export interface ReviewCardProps extends Omit<ReviewInterface, 'key'> {}

function ReviewCard({ reviewText, reviewRating, reviewerName, reviewerCaption }: ReviewCardProps): JSX.Element {
  const theme = useTheme({});

  const stars = [];

  if (reviewRating) {
    for (let i = 0; i < reviewRating; i += 1) {
      stars.push(<Icon icon="fa fa-star" className="mr-1" key={i} />);
    }
  }

  return (
    <blockquote className={classes.container}>
      <div className={classes.content}>
        <RichTextContent content={reviewText} />
      </div>
      {reviewRating && (
        <div className={cn(classes.stars, theme.color)}>
          <div>{stars}</div>
        </div>
      )}
      <cite className={classes.author}>
        {reviewerName}
        {reviewerCaption && (
          <>
            , <span>{reviewerCaption}</span>
          </>
        )}
      </cite>
    </blockquote>
  );
}

export default ReviewCard;
