import { ClassesBuilder } from '@utilities';

const classesConfig = {
  pageMain: {
    default: 'pt-[6.5rem]',
    '2xs': '2xs:pt-[7rem]',
    md: 'md:pt-[8.5rem]',
  },
  pageContent: {
    default: 'bg-white rounded-3xl  -my-6 relative z-10 overflow-hidden shadow-[0_0_24px_rgba(0,0,0,0.08)]',
  },
};

export default new ClassesBuilder(classesConfig);
