import { ClassesBuilder } from '@utilities';

const classesConfig = {
  container: {
    default: 'grid grid-cols-1 gap-2 ',
    sm: 'sm:gap-8',
    lg: 'lg:grid-cols-8 lg:gap-10',
  },
  content: {
    md: 'md:mt-0 md:mr-4',
    lg: 'lg:col-span-3 lg:mr-6',
  },
  tileWrapper: {
    default: 'grid grid-cols-1 gap-4 content-start',
    sm: 'sm:grid-cols-2',
    md: 'md:gap-6',
    lg: 'lg:col-span-5',
  },
};

export default new ClassesBuilder(classesConfig);
