import NextLink from 'next/link';
import { BlogCategory, BlogCategoryList as BlogCategoryListInterface } from '@interfaces';
import { Content } from '@components/Content';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './BlogCategoryList.classes';

export interface BlogCategoryListProps extends BlogCategoryListInterface {}

function BlogCategoryList({
  caption,
  captionImage,
  title,
  categories,
  blockTheme,
  blockIdentifier,
}: BlogCategoryListProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  return (
    <Theme.Provider value={theme}>
      <div className={classes.componentWrapper}>
        <Content caption={caption} captionImage={captionImage} title={title} />
        <div className={classes.listContainer}>
          {categories.map((category: BlogCategory) => (
            <NextLink
              key={category.key}
              href={category.url}
              className={classes.pill}
              data-element-click={`link[${category.name}]_${blockIdentifier}`}
            >
              {category.name} ({category.totalPosts})
            </NextLink>
          ))}
        </div>
      </div>
    </Theme.Provider>
  );
}

export default BlogCategoryList;
