import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { InputHTMLAttributes } from 'react';
import cn from 'classnames';

import { ShortAnswerField as ShortAnswerFieldInterface } from '@interfaces/forms';

import classes from './ShortAnswerField.classes';

export interface ShortAnswerFieldProps extends ShortAnswerFieldInterface {
  isDirty: boolean;
  isInvalid: boolean;
  register: UseFormRegister<FieldValues>;
  registerOptions: RegisterOptions;
}

function ShortAnswerField({
  alias,
  autocompleteAttribute,
  fieldType,
  id,
  isDirty = false,
  isInvalid = false,
  maximumLength,
  placeholder,
  register,
  registerOptions,
}: ShortAnswerFieldProps): JSX.Element {
  const attributes: InputHTMLAttributes<any> = { id, type: fieldType };
  const inputClasses = cn(classes.input, { [classes.invalid]: isInvalid && isDirty });
  const regOptions = { ...registerOptions, maxLength: maximumLength };

  if (autocompleteAttribute) attributes.autoComplete = autocompleteAttribute;
  if (maximumLength) attributes.maxLength = maximumLength;
  if (placeholder) attributes.placeholder = placeholder;

  return <input className={inputClasses} {...attributes} {...register(alias, regOptions)} />;
}

export default ShortAnswerField;
