import { ClassesBuilder } from '@utilities';

const classesConfig = {
  section: {
    default: 'px-6',
    md: 'md:px-8',
  },
  container: 'container mx-auto',
  grid: {
    default: 'grid grid-cols-12 gap-6',
    sm: 'sm:gap-8',
    md: 'md:gap-12',
    lg: 'lg:gap-14',
  },
  area: {
    default: 'flex flex-col gap-6',
    md: 'md:gap-8',
  },
};

export default new ClassesBuilder(classesConfig);
