import { ClassesBuilder } from '@utilities';

const classesConfig = {
  contentContainer: {
    default: 'mb-4',
    md: 'md:mt-0 md:mb-6',
  },
  container: 'grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-8',
  card: 'flex h-full flex-col gap-4 rounded-xl bg-white p-4 shadow-xl',
  cardContent: 'flex h-full flex-col gap-2 text-center',
  title: 'block text-lg font-bold not-italic',
  button: 'mt-auto',
  caseStudyImage: `rounded-lg`,
};

export default new ClassesBuilder(classesConfig);
