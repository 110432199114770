import { ClassesBuilder } from '@utilities';

const classesConfig = {
  videoWithContent: {
    default: 'grid grid-cols-1 gap-8 items-center',
    md: 'md:grid-cols-2',
    lg: 'lg:gap-12',
  },
  contentContainer: {
    md: 'md:mt-0 md:mb-0',
  },
  contentContainerVideoTop: {
    default: 'order-3',
    sm: 'sm:mt-4',
  },
  contentContainerVideoBottom: {
    default: 'order-1',
  },
  contentContainerVideoLeft: {
    md: 'md:order-3 md:ml-6',
    lg: 'lg:ml-10',
  },
  contentContainerVideoRight: {
    md: 'md:order-1 md:mr-6',
    lg: 'lg:mr-10',
  },
  videoContainer: {
    default: 'flex items-center justify-center order-2',
  },
  video: 'aspect-video w-full',
};

export default new ClassesBuilder(classesConfig);
