import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { InputHTMLAttributes } from 'react';
import cn from 'classnames';

import { SingleChoiceField as SingleChoiceFieldInterface } from '@interfaces/forms';
import { useTheme } from '@utilities';

import classes from './SingleChoiceField.classes';

export interface SingleChoiceFieldProps extends SingleChoiceFieldInterface {
  isDirty: boolean;
  isInvalid: boolean;
  register: UseFormRegister<FieldValues>;
  registerOptions: RegisterOptions;
}

function SingleChoiceField({
  alias,
  id,
  isDirty,
  isInvalid,
  preValues,
  register,
  registerOptions,
}: SingleChoiceFieldProps): JSX.Element {
  const theme = useTheme({});
  const containerClasses = cn(classes.container, { [classes.invalid]: isInvalid && isDirty });

  return (
    <div className={containerClasses}>
      {preValues.map((preValue) => {
        const itemID = `${id}_radio_${preValue.value}`;
        const attributes: InputHTMLAttributes<any> = { id: itemID, type: 'radio', value: preValue.value };

        return (
          <div key={itemID} className={classes.item}>
            <input className={cn(classes.input, theme.radio)} {...attributes} {...register(alias, registerOptions)} />
            <label className={classes.label} htmlFor={itemID}>
              {preValue.caption || preValue.value}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default SingleChoiceField;
