import NextImage from 'next/image';
import cn from 'classnames';

import { RichTextContent } from '@components/RichTextContent';
import { Image, Theme as ThemeInterface } from '@interfaces';
import { Button, ButtonProps } from '@components/Button';
import { Link, LinkProps } from '@components/Link';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './Content.classes';

export interface ContentProps {
  className?: string;
  caption?: string;
  captionImage?: Image;
  altText?: string;
  title?: string;
  summary?: string;
  richText?: string;
  blockTheme?: ThemeInterface;
  buttonProps?: ButtonProps;
  linkProps?: LinkProps;
}

function Content({
  className,
  caption,
  captionImage,
  title,
  summary,
  richText,
  buttonProps,
  linkProps,
  blockTheme,
}: ContentProps): JSX.Element | null {
  const theme = useTheme({ blockTheme });

  if (!caption && !title && !summary && !richText && !buttonProps && !linkProps) return null;

  const showText = caption || title || summary || richText;
  const showActions = buttonProps || linkProps;

  return (
    <Theme.Provider value={theme}>
      <div className={cn(className, classes.contentContainer)}>
        {showText && (
          <div className={classes.textContainer}>
            {caption && <div className={cn(classes.caption, theme.color)}>{caption}</div>}
            {captionImage && (
              <div className={classes.captionImageContainer}>
                <NextImage
                  src={captionImage.src}
                  alt={captionImage.alt || ''}
                  width={captionImage.width}
                  height={captionImage.height}
                  sizes="100vw"
                  quality={75}
                  className={classes.captionImage}
                />
              </div>
            )}
            {title && <h2 className={cn(classes.title, theme.titleText)}>{title}</h2>}
            {summary && <p className={cn(classes.summary, theme.bodyText)}>{summary}</p>}
            {richText && (
              <div className={classes.richTextContainer}>
                <RichTextContent content={richText} />
              </div>
            )}
          </div>
        )}
        {showActions && (
          <div className={classes.actionsContainer}>
            {buttonProps && <Button {...buttonProps} />}
            {linkProps && <Link {...linkProps} />}
          </div>
        )}
      </div>
    </Theme.Provider>
  );
}

export default Content;
