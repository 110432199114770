import { useEffect, useState } from 'react';
import GoogleTagManager from 'react-gtm-module';

import pushToDataLayer from '@utilities/pushToDataLayer';
import { TrackingConfig } from '@interfaces';

export interface TrackingProps extends TrackingConfig {}
function Tracking({ googleTagManagerContainerID, googleAnalyticsMeasurementID }: TrackingProps): JSX.Element | null {
  const [tagManagerInitialized, setTagManagerInitialized] = useState(false);

  useEffect(() => {
    if (googleTagManagerContainerID && !tagManagerInitialized) {
      const gtmConfig = { gtmId: googleTagManagerContainerID };
      GoogleTagManager.initialize(gtmConfig);

      if (googleAnalyticsMeasurementID) pushToDataLayer({ GA_ID: googleAnalyticsMeasurementID });

      setTagManagerInitialized(true);
    }
  }, [googleTagManagerContainerID, googleAnalyticsMeasurementID, tagManagerInitialized]);

  return null;
}

export default Tracking;
