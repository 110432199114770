import NextImage from 'next/image';

import { Button } from '@components/Button';

import classes from './HeroUnit404.classes';

function HeroUnit404(): JSX.Element {
  return (
    <div className={classes.hero404}>
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <div className={classes.imageContainerInner}>
            <NextImage
              src="/assets/404-graphic.svg"
              alt="404 - page not found"
              width={640}
              height={170}
              sizes="100vw"
              quality={75}
              className={classes.image}
              priority
            />
          </div>
        </div>
        <section className={classes.contentContainer}>
          <header>
            <h1 className={classes.heading}>Oops, something&apos;s gone wrong!</h1>
          </header>
          <div className={classes.message}>We can&apos;t seem to find the page you&apos;re looking for</div>
          <footer className={classes.footer}>
            <Button linkType="content" href="/" elementClick="button[Go to homepage]_HeroUnit404">
              Go to homepage
            </Button>
          </footer>
        </section>
      </div>
    </div>
  );
}

export default HeroUnit404;
