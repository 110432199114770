import { NextSeo } from 'next-seo';

import { Page as PageInterface, PageSection as PageSectionInterface } from '@interfaces';
import { HeroUnitCenter } from '@components/HeroUnitCenter';
import { PageSection } from '@components/PageSection';
import { HeroUnit } from '@components/HeroUnit';
import { Theme } from '@contexts';

import classes from './Page.classes';

export interface PageProps extends PageInterface {}

function Page({ heroUnit, sections, seoProps, theme }: PageProps): JSX.Element {
  return (
    <Theme.Provider value={theme}>
      {seoProps && <NextSeo {...seoProps} />}
      <main className={classes.pageMain}>
        {heroUnit && heroUnit.centerAligned && <HeroUnitCenter {...heroUnit} />}
        {heroUnit && !heroUnit.centerAligned && <HeroUnit {...heroUnit} />}
        {sections && sections.length > 0 && (
          <div className={classes.pageContent}>
            {sections.map((section: PageSectionInterface) => (
              <PageSection {...section} />
            ))}
          </div>
        )}
      </main>
    </Theme.Provider>
  );
}

export default Page;
