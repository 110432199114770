import NextImage from 'next/image';
import cn from 'classnames';

import { FeaturedReview as FeaturedReviewInterface } from '@interfaces';
import ReviewCard from '@components/ReviewCard/ReviewCard';
import { ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { LinkProps } from '@components/Link';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './FeaturedReview.classes';

export interface FeaturedReviewProps extends FeaturedReviewInterface {}

function FeaturedReview({
  caption,
  captionImage,
  title,
  image,
  link,
  button,
  reviewText,
  reviewerName,
  reviewerCaption,
  reviewRating,
  blockTheme,
  blockIdentifier,
  customID,
}: FeaturedReviewProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  const wrapperClass = image ? 'md:col-span-6' : 'md:col-span-12';

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }
  return (
    <Theme.Provider value={theme}>
      <div className={!image ? '' : classes.featuredReview} id={customID}>
        {image && (
          <div className={classes.imageContainer}>
            <div className={classes.imageInner}>
              <NextImage
                src={image.src}
                alt={image.alt || ''}
                width={image.width}
                height={image.height}
                sizes="100vw"
                quality={75}
                className={classes.image}
              />
            </div>
          </div>
        )}

        <div className={cn(classes.reviewWrapper, wrapperClass)}>
          <Content
            className={classes.reviewContent}
            caption={caption}
            captionImage={captionImage}
            title={title || ''}
            linkProps={linkProps}
            buttonProps={buttonProps}
          />

          <div className={cn(classes.reviewCardWrapper, { 'grid-cols-12': !image })}>
            <ReviewCard {...{ reviewText, reviewRating, reviewerName, reviewerCaption }} />
          </div>
        </div>
      </div>
    </Theme.Provider>
  );
}

export default FeaturedReview;
