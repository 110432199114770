import React from 'react';

import { BlogPostSidebarItem as BlogPostSidebarItemInterface } from '@interfaces';

import classes from './BlogPostSidebarItem.classes';

export interface BlogPostSidebarItemProps extends BlogPostSidebarItemInterface {}

function BlogPostSidebarItem({ heading, children }: BlogPostSidebarItemProps) {
  return (
    <div className={classes.container}>
      <h1 className={classes.heading}>{heading}</h1>
      {children}
    </div>
  );
}

export default BlogPostSidebarItem;
