import { ClassesBuilder } from '@utilities';

const classesConfig = {
  heading: {
    default: 'mb-4',
    xs: 'xs:flex xs:items-end xs:justify-between',
  },
  biographies: {
    default: 'grid gap-4',
    xs: 'xs:grid-cols-2',
    sm: 'sm:grid-cols-2',
    md: 'md:grid-cols-3 md:gap-6',
    lg: 'lg:grid-cols-4',
  },
  card: 'rounded-lg shadow-lg group bg-white h-full flex flex-col items-stretch justify-start p-2',
  imageContainer: 'relative rounded-md overflow-hidden',
  image: 'object-cover aspect-1 w-full h-auto',
  contentContainer: 'pt-4 p-2',
  name: {
    default: 'font-semibold text-click-blue leading-tight',
    md: 'md:text-lg',
  },
  jobTitle: 'text-sm text-click-para',
  link: 'text-sm font-bold hover:text-click-blue transition',
  vacancyContentContainer:
    'group h-full p-4 rounded-md bg-click-grey transition hover:bg-click-blue flex flex-col min-h-[300px]',
  vacancyName: 'text-sm font-semibold',
  vacancyTitle: 'text-lg xl:text-xl font-semibold text-click-blue transition group-hover:text-white flex-1',
  vacancyLink: 'text-sm font-bold transition',
};

export default new ClassesBuilder(classesConfig);
