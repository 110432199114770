import { ClassesBuilder } from '@utilities';

const classesConfig = {
  imageWithContent: {
    default: 'grid grid-cols-1 gap-8 items-center',
    xl: 'xl:grid-cols-2 xl:gap-12',
  },
  contentContainer: {
    default: 'w-full',
    md: 'md:mt-0 md:mb-0',
  },
  contentContainerImageTop: {
    default: 'order-3',
    sm: 'sm:mt-4',
  },
  contentContainerImageBottom: {
    default: 'order-1',
  },
  contentContainerImageLeft: {
    xl: 'xl:order-3 xl:ml-10',
  },
  contentContainerImageRight: {
    xl: 'xl:order-1 xl:mr-10',
  },
  imageContainer: {
    default: 'flex items-center justify-center order-2 w-full',
  },
  imageContainerInner: 'relative w-full',
  image: 'w-full h-auto',
};

export default new ClassesBuilder(classesConfig);
