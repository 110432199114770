/* eslint-disable react/no-danger */
import { DarkBackground } from '@contexts';
import { useContext } from 'react';
import { useTheme } from '@utilities';
import { Theme } from '@interfaces';
import classes from './RichTextContent.classes';

export interface RichTextContentProps {
  content: string;
  blockTheme?: Theme;
}

function RichTextContent({ content, blockTheme }: RichTextContentProps): JSX.Element {
  const theme = useTheme({ blockTheme });
  const darkBackground = useContext(DarkBackground);
  function replaceImageSrc(imageSrc: string) {
    const mediaUrl = process.env.MEDIA_URL || '';
    const modifiedMediaUrl = mediaUrl.endsWith('/') ? mediaUrl : `${mediaUrl}/`;

    if (imageSrc.startsWith('/media')) {
      const modifiedSrc = `${modifiedMediaUrl}${imageSrc.slice(1)}`;
      return modifiedSrc;
    }
    return imageSrc;
  }

  function replaceImageSrcInString(htmlString: string) {
    const imgSrcRegex = /<img[^>]+src=['"]([^'"]+)['"][^>]*>/g;
    const replacedHtmlString = htmlString.replace(imgSrcRegex, (match, src) => {
      const modifiedSrc = replaceImageSrc(src);
      return match.replace(src, modifiedSrc);
    });

    return replacedHtmlString;
  }

  function addTextColorToATags(htmlString: string, textColor: string) {
    const aTagRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1(?:[^>]*?)>/g;
    const replacedHtmlString = htmlString.replace(aTagRegex, (match) => match.replace('>', ` class="${textColor}">`));

    return replacedHtmlString;
  }

  const modifiedHtmlStringWithImgSrc = replaceImageSrcInString(content);
  const modifiedHtmlString = addTextColorToATags(modifiedHtmlStringWithImgSrc, theme.color);

  return (
    <div
      className={darkBackground ? classes.richTextDark : classes.richText}
      dangerouslySetInnerHTML={{ __html: modifiedHtmlString }}
    />
  );
}

export default RichTextContent;
