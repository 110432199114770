import { ClassesBuilder } from '@utilities';

const classesConfig = {
  review: {
    default: 'flex flex-col items-center justify-center gap-4 p-4 py-8 rounded-xl bg-click-grey',
    sm: 'sm:py-12 sm:p-6',
    md: 'md:py-16 md:p-8',
  },
  reviewText: {
    default: 'text-md text-click-para leading-relaxed text-center w-full',
    md: 'md:text-lg',
    lg: 'lg:text-xl',
  },
  reviewer: 'flex items-center gap-3',
  reviewerImage: 'rounded-full w-12 h-12 bg-black overflow-hidden border-2',
  image: 'object-cover w-full h-full',
  reviewerName: 'text-click-blue font-bold text-md not-italic',
  reviewerCaption: 'text-click-para font-normal text-sm not-italic',
};

export default new ClassesBuilder(classesConfig);
