import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { SelectHTMLAttributes } from 'react';
import cn from 'classnames';

import { MultipleChoiceField as MultipleChoiceFieldInterface, FormPreValue } from '@interfaces/forms';

import classes from './MultipleChoiceField.classes';

export interface MultipleChoiceFieldProps extends MultipleChoiceFieldInterface {
  isDirty: boolean;
  isInvalid: boolean;
  register: UseFormRegister<FieldValues>;
  registerOptions: RegisterOptions;
}

function MultipleChoiceField({
  alias,
  defaultValue,
  id,
  isDirty = false,
  isInvalid = false,
  preValues,
  register,
  registerOptions,
}: MultipleChoiceFieldProps): JSX.Element {
  const attributes: SelectHTMLAttributes<any> = {
    id,
    defaultValue: defaultValue ? [defaultValue] : [''],
    multiple: true,
  };
  const selectClasses = cn(classes.select, { [classes.invalid]: isInvalid && isDirty });

  return (
    <select className={selectClasses} {...attributes} {...register(alias, registerOptions)}>
      {preValues.map((preValue: FormPreValue) => (
        <option className={classes.option} key={preValue.value} value={preValue.value}>
          {preValue.caption || preValue.value}
        </option>
      ))}
    </select>
  );
}

export default MultipleChoiceField;
