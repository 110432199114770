import { ClassesBuilder } from '@utilities';

const classesConfig = {
  title: {
    default: 'mb-4 text-2xl font-bold text-click-blue',
    sm: 'sm:mb-6',
    lg: 'lg:text-3xl',
  },
};

export default new ClassesBuilder(classesConfig);
