import { ClassesBuilder } from '@utilities';

const classesConfig = {
  featuredReview: {
    default: 'flex flex-col gap-4',
    md: 'md:grid md:grid-cols-12 md:gap-16',
  },
  imageContainer: {
    default: 'order-2 flex justify-center items-center pt-6',
    md: 'md:col-span-6 md:order-1 md:pt-0',
    lg: 'lg:col-span-5 lg:pt-0',
  },
  imageInner: 'relative w-full',
  image: 'w-full h-auto',
  reviewWrapper: {
    default: 'flex flex-col gap-4 order-1',
    md: 'md:gap-6 md:order-2 md:col-span-8',
    lg: 'lg:col-span-7',
    xl: 'xl:col-span-7',
  },
  footer: 'flex items-center m-2',
};

export default new ClassesBuilder(classesConfig);
