import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { BreadcrumbItem, Breadcrumbs } from '@components/Breadcrumbs';
import { BlogPost, Theme as ThemeInterface } from '@interfaces';
import { BlogPostCard } from '@components/BlogPostCard';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';
import { Icon } from '@components/Icon';
import classes from './BlogPostGrid.classes';

export interface BlogPostGridProps {
  name: string;
  blogPosts: BlogPost[];
  blockTheme?: ThemeInterface;
  blockIdentifier: string;
  currentPage: number;
  totalPages: number;
}

function BlogPostGrid({
  name,
  blogPosts,
  blockTheme,
  blockIdentifier,
  currentPage,
  totalPages,
}: BlogPostGridProps): JSX.Element {
  const theme = useTheme({ blockTheme });
  const router = useRouter();
  const getPageUrl = (page: number) => {
    const pathParts = router.asPath.split('?')[0].split('/').filter(Boolean);

    // Check if the last part is a number
    if (Number.isNaN(Number(pathParts[pathParts.length - 1]))) {
      // If not, we're on the first page, so just add the page number
      return `/${pathParts.join('/')}/${page}`;
    }
    // If it is, replace the last part with the new page number
    pathParts[pathParts.length - 1] = page.toString();
    return `/${pathParts.join('/')}`;
  };

  const breadcrumbs: BreadcrumbItem[] = [
    { name: 'Blog', url: '/blog' },
    ...router.asPath
      .split('/')
      .slice(2)
      .filter(Boolean)
      .filter((part) => Number.isNaN(Number(part)))
      .map((part, index, array) => ({
        name: decodeURIComponent(part)
          .replace(/-/g, ' ')
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
        url: `/blog/${array.slice(0, index + 1).join('/')}`,
      })),
  ];

  const getVisiblePageNumbers = () => {
    const visiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    const endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (endPage - startPage + 1 < visiblePages) {
      startPage = Math.max(1, endPage - visiblePages + 1);
    }

    const pages = [];
    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) {
        pages.push('...');
      }
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push('...');
      }
      pages.push(totalPages);
    }
    return pages;
  };
  if (blogPosts.length < 1) {
    return (
      <Theme.Provider value={theme}>
        <div className={classes.container}>
          <Breadcrumbs items={breadcrumbs} />
          <div className={classes.header}>
            <h1 className={classes.title}>{name}</h1>
          </div>
          <div className="text-center py-16 px-4">
            <Icon icon="fa fa-newspaper-o" className="text-6xl text-gray-300 mb-4" />
            <h2 className="text-2xl font-bold text-gray-700 mb-2">No Posts Found</h2>
            <p className="text-gray-500 mb-8">
              We couldn&apos;t find any posts in this category. They might have been moved or deleted.
            </p>
            <Link href="/blog" passHref>
              <button
                type="button"
                className="bg-click-orange hover:bg-click-orange-light text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                Return to Blog Home
              </button>
            </Link>
          </div>
        </div>
      </Theme.Provider>
    );
  }
  return (
    <Theme.Provider value={theme}>
      <div className={classes.container}>
        <Breadcrumbs items={breadcrumbs} />
        <div className={classes.header}>
          <h1 className={classes.title}>{name}</h1>
        </div>
        <div className="flex items-center justify-between mb-6">
          <div>
            Page <strong>{currentPage}</strong> of {totalPages}
          </div>
        </div>
        <div className={classes.grid}>
          {blogPosts.map((blogPost: BlogPost) => (
            <div key={blogPost.key}>
              <BlogPostCard
                date={blogPost.date}
                elementClick={`blogPost[${blogPost.heading}]_${blockIdentifier}`}
                heading={blogPost.heading}
                image={blogPost.image}
                theme={theme}
                url={blogPost.url}
              />
            </div>
          ))}
        </div>
        <div className={classes.pagination}>
          <div className={classes.paginationInner}>
            <Link href={getPageUrl(currentPage - 1)} passHref>
              <button type="button" disabled={currentPage === 1} className={classes.paginationButton}>
                <Icon icon="fa fa-chevron-left" className="sm:hidden text-xs sm:text-[8px]" />
                <span className="hidden sm:block">Previous</span>
              </button>
            </Link>
            {getVisiblePageNumbers().map((number, index) => (
              <React.Fragment key={index}>
                {typeof number === 'number' ? (
                  <Link href={getPageUrl(number)} passHref>
                    <button
                      type="button"
                      className={
                        currentPage === number ? classes.paginationButtonActive : classes.paginationButtonInactive
                      }
                    >
                      {number}
                    </button>
                  </Link>
                ) : (
                  <span className={classes.paginationEllipsis}>{number}</span>
                )}
              </React.Fragment>
            ))}
            <Link href={getPageUrl(currentPage + 1)} passHref>
              <button type="button" disabled={currentPage === totalPages} className={classes.paginationButton}>
                <span className="hidden sm:block">Next</span>
                <Icon icon="fa fa-chevron-right" className="sm:hidden text-xs sm:text-[8px]" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Theme.Provider>
  );
}

export default BlogPostGrid;
