import { ClassesBuilder } from '@utilities';

const classesConfig = {
  heading: {
    default: 'mb-4',
    xs: 'xs:flex xs:items-end xs:justify-between',
    md: 'md:mb-6',
  },
  blogPosts: {
    default:
      'w-auto -m-6 p-6 flex flex-wrap gap-6 overflow-x-auto overscroll-x-contain	snap-proximity snap-x no-scrollbar',
    md: 'md:-mx-8 md:p-8',
    lg: 'lg:grid lg:grid-cols-3 lg:gap-8',
  },
  blogPostsCard: {
    default: 'w-full',
  },
};

export default new ClassesBuilder(classesConfig);
