import cn from 'classnames';

import { SteppedPromotionItem } from '@interfaces';
import { ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { Link, LinkProps } from '@components/Link';
import { RichTextContent } from '@components/RichTextContent';
import { getBorder } from '@utilities';
import { Theme } from '@contexts';

import classes from './SteppedPromotion.classes';

import useSteppedPromotion, { SteppedPromotionProps } from './useSteppedPromotion';

function SteppedPromotion({
  caption,
  captionImage,
  title,
  items,
  blockTheme,
  blockIdentifier,
  columns,
  button,
  link,
}: SteppedPromotionProps): JSX.Element {
  const { theme, gridColumns } = useSteppedPromotion({ columns, blockTheme });
  const border = getBorder(theme.name);
  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }

  return (
    <Theme.Provider value={theme}>
      <div>
        <Content
          className={classes.contentContainer}
          caption={caption}
          captionImage={captionImage}
          title={title}
          buttonProps={buttonProps}
          linkProps={linkProps}
        />
        <div className={cn(classes.grid, gridColumns)}>
          {items.map((item: SteppedPromotionItem) => (
            <div className={cn(classes.item, border)} key={item.key}>
              <div className={cn(classes.step, theme.background)}>{item.step}</div>
              <h3 className={classes.title}>{item.title}</h3>
              <div className={classes.summary}>
                <RichTextContent content={item.summary} />
              </div>
              {item.link && (
                <Link
                  {...item.link}
                  icon="fas fa-arrow-right"
                  elementClick={`link[${item.link?.name}]_${blockIdentifier}`}
                >
                  {item.link.name}
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </Theme.Provider>
  );
}

export default SteppedPromotion;
