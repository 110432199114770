import { ClassesBuilder } from '@utilities';

const classesConfig = {
  hero404: '-mt-28 pt-28 px-4 flex items-center justify-center bg-click-grey',
  container: {
    default: 'container mx-auto py-8',
    sm: 'sm:py-12',
    md: 'md:py-16',
    lg: 'lg:py-20',
    xl: 'xl:py-24',
  },
  imageContainer: 'm-4 flex justify-center',
  imageContainerInner: 'relative mt-16',
  image: 'h-40 w-auto',
  contentContainer: 'm-4',
  heading: {
    default: 'text-center text-2xl font-bold text-click-blue mb-4',
    sm: 'sm:text-3xl sm:mb-6',
    md: 'md:text-4xl',
    lg: 'lg:text-5xl',
  },
  message: {
    default: 'text-center text-base text-click-text mb-4',
    sm: 'sm:mb-6',
    md: 'md:text-lg',
    lg: 'lg:text-xl',
  },
  footer: 'flex justify-center gap-4',
};

export default new ClassesBuilder(classesConfig);
