import { ClassesBuilder } from '@utilities';

const classesConfig = {
  container: ``,
  list: `list-none`,
  category: `py-2 text-[#555555]`,
  listItem: `flex items-center`,
  marker: `-ml-6 w-2 h-8 bg-orange-500 mr-4`,
  currentCategory: `text-click-orange`,
  tagContainer: `flex flex-wrap gap-2`,
  tags: `border border-gray-300 rounded-md px-3 py-1 text-sm text-[#555555]`,
  latestPosts: `flex flex-col justify-between`,
  latestPostItem: `my-2`,
};

export default new ClassesBuilder(classesConfig);
