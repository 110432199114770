import { BlogPostList as BlogPostListInterface, BlogPost } from '@interfaces';
import { BlogPostCard } from '@components/BlogPostCard';
import { ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { LinkProps } from '@components/Link';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './BlogPostList.classes';

export interface BlogPostListProps extends BlogPostListInterface {}

function BlogPostList({
  caption,
  captionImage,
  title,
  richText,
  button,
  link,
  blogPosts,
  blockTheme,
  blockIdentifier,
}: BlogPostListProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }

  return (
    <Theme.Provider value={theme}>
      <div>
        <Content
          className={classes.contentContainer}
          caption={caption}
          captionImage={captionImage}
          title={title}
          richText={richText}
          buttonProps={buttonProps}
          linkProps={linkProps}
        />
        <div className={classes.blogPosts}>
          {blogPosts.map((blogPost: BlogPost) => (
            <div key={blogPost.key}>
              <BlogPostCard
                date={blogPost.date}
                elementClick={`blogPost[${blogPost.heading}]_${blockIdentifier}`}
                heading={blogPost.heading}
                image={blogPost.image}
                theme={theme}
                url={blogPost.url}
              />
            </div>
          ))}
        </div>
      </div>
    </Theme.Provider>
  );
}

export default BlogPostList;
