/* eslint-disable react/destructuring-assignment */
import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { useContext } from 'react';

import { FormField as FormFieldInterface } from '@interfaces/forms';
import { FormData } from '@contexts';

import { MultipleChoiceField, MultipleChoiceFieldProps } from '../MultipleChoiceField';
import { SingleChoiceField, SingleChoiceFieldProps } from '../SingleChoiceField';
import { ShortAnswerField, ShortAnswerFieldProps } from '../ShortAnswerField';
import { LongAnswerField, LongAnswerFieldProps } from '../LongAnswerField';
import { DropdownField, DropdownFieldProps } from '../DropdownField';
import { CheckboxField, CheckboxFieldProps } from '../CheckboxField';

import classes from './FormField.classes';

export interface FormFieldProps extends FormFieldInterface {
  errors: any;
  isDirty: boolean;
  isInvalid: boolean;
  onChange: () => void;
  register: UseFormRegister<FieldValues>;
}

function FormField(props: FormFieldProps): JSX.Element | null {
  const formData = useContext(FormData);
  const showRequiredError =
    props.required && props.isInvalid && props.isDirty && props.errors[props.alias]?.type === 'required';
  const showPatternError =
    props.pattern && props.isInvalid && props.isDirty && props.errors[props.alias]?.type === 'pattern';

  const registerOptions: RegisterOptions = {
    required: { value: props.required, message: props.requiredErrorMessage },
    onChange: () => props.onChange(),
  };
  if (props.pattern) registerOptions.pattern = { value: new RegExp(props.pattern), message: props.patternErrorMessage };

  return (
    <div>
      {props.name !== 'Checkbox' && (
        <label htmlFor={props.id} className={classes.label}>
          {props.caption}
          {formData.fieldIndicationType === 'MarkMandatoryFields' && props.required && (
            <span className={classes.requiredIndicator}>{formData.indicator}</span>
          )}
          {formData.fieldIndicationType === 'MarkOptionalFields' && !props.required && (
            <span className={classes.optionalIndicator}>{formData.indicator}</span>
          )}
        </label>
      )}
      {props.name === 'Checkbox' && (
        <CheckboxField {...(props as unknown as CheckboxFieldProps)} registerOptions={registerOptions} />
      )}
      {props.name === 'Dropdown' && (
        <DropdownField {...(props as unknown as DropdownFieldProps)} registerOptions={registerOptions} />
      )}
      {props.name === 'LongAnswer' && (
        <LongAnswerField {...(props as unknown as LongAnswerFieldProps)} registerOptions={registerOptions} />
      )}
      {props.name === 'MultipleChoice' && (
        <MultipleChoiceField {...(props as unknown as MultipleChoiceFieldProps)} registerOptions={registerOptions} />
      )}
      {props.name === 'ShortAnswer' && (
        <ShortAnswerField {...(props as unknown as ShortAnswerFieldProps)} registerOptions={registerOptions} />
      )}
      {props.name === 'SingleChoice' && (
        <SingleChoiceField {...(props as unknown as SingleChoiceFieldProps)} registerOptions={registerOptions} />
      )}

      {showRequiredError && <p className={classes.errorText}>{props.requiredErrorMessage}</p>}
      {showPatternError && <p className={classes.errorText}>{props.patternErrorMessage}</p>}
      {props.helpText && <p className={classes.helpText}>{props.helpText}</p>}
    </div>
  );
}

export default FormField;
