import NextImage from 'next/image';

import { Button, ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { LinkProps } from '@components/Link';
import { Theme } from '@contexts';
import { CaseStudiesList as CaseStudiesListInterface, CaseStudy } from '@interfaces';
import { useTheme } from '@utilities';

import classes from './CaseStudiesList.classes';

export interface CaseStudiesListProps extends CaseStudiesListInterface {}

function CaseStudiesList({
  caption,
  captionImage,
  title,
  richText,
  button,
  link,
  caseStudies,
  blockTheme,
  blockIdentifier,
}: CaseStudiesListProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }

  return (
    <Theme.Provider value={theme}>
      <div>
        <Content
          className={classes.contentContainer}
          caption={caption}
          captionImage={captionImage}
          title={title}
          richText={richText}
          buttonProps={buttonProps}
          linkProps={linkProps}
        />
        <div className={classes.container}>
          {caseStudies.length > 0 &&
            caseStudies.map((caseStudy: CaseStudy) => (
              <div className={classes.card} key={caseStudy.key}>
                {caseStudy.image != null && (
                  <NextImage
                    src={caseStudy.image.src}
                    alt={caseStudy.image.alt || ''}
                    width={caseStudy.image.width}
                    height={caseStudy.image.height}
                    sizes="100vw"
                    quality={75}
                    className={classes.caseStudyImage}
                  />
                )}
                <div className={classes.cardContent}>
                  <em className={classes.title}>{caseStudy.title}</em>
                  <Content richText={caseStudy.summary} />
                  {caseStudy.button && (
                    <div className={classes.button}>
                      <Button {...caseStudy.button}>{caseStudy.button.name}</Button>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Theme.Provider>
  );
}

export default CaseStudiesList;
