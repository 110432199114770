import { ClassesBuilder } from '@utilities';

const classesConfig = {
  titleGrid: {
    default: 'hidden gap-8 copy',
    lg: 'lg:grid lg:grid-cols-2',
  },
  textContainer: {
    default: 'grid gap-4',
  },
  caption: {
    default: 'text-sm font-bold -mb-2',
    sm: 'sm:text-base',
  },
  captionImageContainer: {
    default: 'relative w-full h-6 -mb-2',
    md: 'md:h-8',
    xl: 'xl:h-10',
  },
  captionImage: 'w-auto h-full',
  title: {
    default: 'text-2xl font-bold text-click-blue',
    lg: 'lg:text-3xl',
  },
  contentGrid: {
    default: 'grid gap-4',
    md: 'md:gap-6',
    lg: 'lg:gap-8 lg:grid-cols-2',
  },
  inlineCaption: {
    lg: 'lg:hidden',
  },
  inlineTitle: {
    lg: 'lg:hidden',
  },
  actionsContainer: {
    default: 'mt-0',
    sm: 'sm:mt-0',
  },
};

export default new ClassesBuilder(classesConfig);
