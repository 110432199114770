import NextImage from 'next/image';
import cn from 'classnames';
import { ImageWithContent as ImageWithContentInterface } from '@interfaces';
import { ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { LinkProps } from '@components/Link';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';
import classes from './ImageWithContent.classes';

export interface ImageWithContentProps extends ImageWithContentInterface {}

function ImageWithContent({
  caption,
  captionImage,
  title,
  richText,
  image,
  imagePositionMobile,
  imagePositionDesktop,
  button,
  link,
  blockTheme,
  blockIdentifier,
  customID,
}: ImageWithContentProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  const contentContainerClasses = [
    classes.contentContainer,
    imagePositionMobile === 'top' ? classes.contentContainerImageTop : classes.contentContainerImageBottom,
    imagePositionDesktop === 'left' ? classes.contentContainerImageLeft : classes.contentContainerImageRight,
  ];

  let buttonProps: ButtonProps | undefined;
  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;
  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }

  return (
    <Theme.Provider value={theme}>
      <div className={classes.imageWithContent} id={customID}>
        <div className={classes.imageContainer}>
          <div className={classes.imageContainerInner}>
            <NextImage
              src={image.src}
              alt={image.alt || ''}
              width={image.width}
              height={image.height}
              sizes="100vw"
              quality={75}
              className={classes.image}
            />
          </div>
        </div>
        <div className={cn(contentContainerClasses)}>
          <Content
            caption={caption}
            captionImage={captionImage}
            title={title}
            richText={richText}
            buttonProps={buttonProps}
            linkProps={linkProps}
          />
        </div>
      </div>
    </Theme.Provider>
  );
}

export default ImageWithContent;
