import { ClassesBuilder } from '@utilities';

const classesConfig = {
  componentWrapper: {
    default: 'grid gap-4',
  },
  listContainer: 'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 mt-2',
  pill: 'inline-block text-center rounded-full border border-gray-200 px-4 py-2 text-sm font-medium text-click-para transition hover:border-click-blue hover:bg-click-blue hover:text-white hover:scale-105 active:scale-95 whitespace-nowrap overflow-hidden text-ellipsis',
};

export default new ClassesBuilder(classesConfig);
