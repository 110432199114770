import { ClassesBuilder } from '@utilities';

const classesConfig = {
  contentContainer: {
    default: 'mb-4',
    sm: 'sm:mb-6',
  },
  address: 'font-semibold text-click-blue not-italic',
};

export default new ClassesBuilder(classesConfig);
