import { ClassesBuilder } from '@utilities';

const classesConfig = {
  link: 'rounded-lg shadow-lg group transition duration-300 bg-white hover:bg-click-blue hover:scale-105 p-4 flex items-center',
  iconWrapper: 'mr-2',
  icon: 'flex items-center justify-center',
  content: 'flex-1',
  name: 'transition text-lg font-semibold text-click-blue group-hover:text-white',
  strapline: 'transition text-xs text-click-para leading-none group-hover:text-white group-hover:text-opacity-75',
};

export default new ClassesBuilder(classesConfig);
