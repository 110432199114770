import NextImage from 'next/image';
import cn from 'classnames';

import { BlogPostHeroUnit as BlogPostHeroUnitInterface } from '@interfaces';
import { BreadcrumbItem, Breadcrumbs } from '@components/Breadcrumbs';
import { RichTextContent } from '@components/RichTextContent';
import { FromNow } from '@components/FromNow';
import { Icon } from '@components/Icon';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './BlogPostHeroUnit.classes';

export interface BlogPostHeroUnitProps extends BlogPostHeroUnitInterface {}

function BlogPostHeroUnit({
  caption,
  title,
  date,
  author,
  richText,
  image,
  blockTheme,
}: BlogPostHeroUnitProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  const breadcrumbs: BreadcrumbItem[] = [{ name: 'Blog', url: '/blog' }, { name: title }];

  return (
    <Theme.Provider value={theme}>
      <div className={classes.blogTitleWithImage}>
        <div className={classes.contentContainer}>
          <header>
            <div className={classes.breadcrumbs}>
              <Breadcrumbs items={breadcrumbs} />
            </div>
            {caption && <div className={cn(classes.caption, theme.color)}>{caption}</div>}
            <h2 className={classes.title}>{title}</h2>
          </header>

          {(date || author) && (
            <div className={classes.infoContainer}>
              {date && (
                <div className={cn(classes.date, theme.color)}>
                  <Icon icon="fa-regular fa-clock" /> <FromNow date={date} />
                </div>
              )}
              {author && (
                <div className={cn(classes.author, theme.color)}>
                  <Icon icon="fa-regular fa-user" /> {author}
                </div>
              )}
            </div>
          )}
          {richText && (
            <div className={classes.richTextContainer}>
              <RichTextContent content={richText} />
            </div>
          )}
        </div>
        <div className={classes.imageContainer}>
          <div className={classes.imageContainerInner}>
            <NextImage
              src={image.src}
              alt={image.alt || ''}
              width={image.width}
              height={image.height}
              sizes="100vw"
              className={classes.image}
            />
          </div>
        </div>
      </div>
    </Theme.Provider>
  );
}

export default BlogPostHeroUnit;
