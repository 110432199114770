import RelativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

export interface FromNowProps {
  date: string;
}

function FromNow({ date }: FromNowProps): JSX.Element | null {
  if (!date) return null;

  dayjs.extend(RelativeTime);
  return <span>{dayjs(date).fromNow()}</span>;
}

export default FromNow;
