import NextLink from 'next/link';

import { Product as ProductTileInterface } from '@interfaces';
import { ProductIcon } from '@components/ProductIcon';

import classes from './ProductTile.classes';

export interface ProductTileProps extends ProductTileInterface {
  elementClick?: string;
}

function ProductTile({ name, url, strapline, icon, theme, elementClick }: ProductTileProps): JSX.Element {
  return (
    <NextLink href={url} className={classes.link} data-element-click={elementClick || null}>
      <div className={classes.iconWrapper}>
        <div className={classes.icon}>
          <ProductIcon icon={icon} name={name} size="lg" theme={theme} />
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.name}>{name}</div>
        <div className={classes.strapline}>{strapline}</div>
      </div>
    </NextLink>
  );
}

export default ProductTile;
