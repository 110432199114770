/* eslint-disable react/no-danger */
import NextLink from 'next/link';
import cn from 'classnames';

import { VacancyList as VacancyListInterface, Vacancy } from '@interfaces';
import { useTheme } from '@utilities';
import { Content } from '@components/Content';
import { Theme } from '@contexts';

import classes from './VacancyList.classes';

export interface VacancyListProps extends VacancyListInterface {}

function VacancyList({
  caption,
  captionImage,
  title,
  summary,
  vacancies,
  blockTheme,
  blockIdentifier,
  customID,
}: VacancyListProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  return (
    <Theme.Provider value={theme}>
      <div className={classes.componentContainer} id={customID}>
        <Content caption={caption} captionImage={captionImage} title={title} />
        <div className={classes.tableBlock}>
          {vacancies.length > 0 && (
            <table className={classes.table}>
              <thead className={classes.headerRow}>
                <tr>
                  <th className={classes.firstHeaderColumn}>Job Title</th>
                  <th className={classes.column}>Location</th>
                  <th className={classes.column}>Department</th>
                  <th className={classes.lastHeaderColumn}>Date</th>
                </tr>
              </thead>
              <tbody>
                {vacancies.map((vacancy: Vacancy) => (
                  <tr key={vacancy.key} className={classes.row}>
                    <td className={classes.mainColumn}>
                      <NextLink
                        className={cn(classes.link, theme.colorHoverAlt)}
                        href={vacancy.url}
                        data-element-click={`link[${vacancy.jobTitle}]_${blockIdentifier}`}
                      >
                        {vacancy.jobTitle}
                      </NextLink>
                    </td>
                    <td className={classes.column}>{vacancy.location}</td>
                    <td className={classes.column}>{vacancy.department}</td>
                    <td className={classes.column}>{vacancy.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {vacancies.length < 1 && <p className={classes.noVacancies}>Sorry, there are no vacancies at this time.</p>}
        </div>
        {summary && <div className={classes.summary} dangerouslySetInnerHTML={{ __html: summary }} />}
      </div>
    </Theme.Provider>
  );
}

export default VacancyList;
