import { ClassesBuilder } from '@utilities';

const classesConfig = {
  contentContainer: {
    default: 'mb-4',
    md: 'md:mt-0 md:mb-6',
  },
  blogPosts: {
    default: 'w-auto -m-6 p-6 flex gap-6 overflow-x-auto overscroll-x-contain	snap-proximity snap-x no-scrollbar',
    md: 'md:-mx-8 md:p-8',
    lg: 'lg:grid lg:grid-cols-3 lg:gap-8',
  },
};

export default new ClassesBuilder(classesConfig);
