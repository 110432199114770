import cn from 'classnames';

import { ImagePromotionItem } from '@interfaces';
import { ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { RichTextContent } from '@components/RichTextContent';
import { ImageOnly as Image } from '@components/ImageOnly';
import { Link, LinkProps } from '@components/Link';
import { Theme } from '@contexts';

import classes from './ImagePromotion.classes';

import useImagePromotion, { ImagePromotionProps } from './useImagePromotion';

function ImagePromotion({
  caption,
  captionImage,
  title,
  items,
  blockTheme,
  blockIdentifier,
  columns,
  customID,
  button,
  link,
}: ImagePromotionProps): JSX.Element {
  const { gridColumns, theme } = useImagePromotion({ columns, blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }

  return (
    <Theme.Provider value={theme}>
      <div id={customID}>
        <Content
          className={classes.contentContainer}
          caption={caption}
          captionImage={captionImage}
          title={title}
          buttonProps={buttonProps}
          linkProps={linkProps}
        />
        <div className={cn(classes.grid, gridColumns)}>
          {items.map((item: ImagePromotionItem) => (
            <div className={classes.item} key={item.key}>
              {item.image && (
                <div className={classes.imageContainer}>
                  {item.imageLink ? (
                    <Link {...item.imageLink}>
                      <Image image={item.image} blockIdentifier={item.key} />
                    </Link>
                  ) : (
                    <Image image={item.image} blockIdentifier={item.key} />
                  )}
                </div>
              )}
              <h3 className={classes.title}>{item.title}</h3>
              <RichTextContent content={item.summary} />
              {item.link && (
                <Link
                  {...item.link}
                  icon="fas fa-arrow-right"
                  elementClick={`link[${item.link?.name}]_${blockIdentifier}`}
                >
                  {item.link.name}
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </Theme.Provider>
  );
}

export default ImagePromotion;
