import Link from 'next/link';

import { BlogPostSidebarItem } from '@components/BlogPostSidebarItem';
import { BlogPost, BlogPostSidebar as BlogPostSidebarProps } from '@interfaces';

import { Theme } from '@contexts';

import classes from './BlogPostSidebar.classes';

function BlogPostSidebar({ categories, latestPosts, blogCategory, blogTags, theme }: BlogPostSidebarProps) {
  return (
    <Theme.Provider value={theme}>
      <div className={classes.container}>
        <BlogPostSidebarItem heading="Categories">
          <div>
            {categories &&
              categories?.map((category, index) => (
                <ul className={classes.list} key={index}>
                  {category.name === blogCategory ? (
                    <li className={classes.listItem}>
                      <div className={classes.marker} />
                      <div className={classes.currentCategory}>
                        <Link href={category.url}>{category.name}</Link>
                      </div>
                    </li>
                  ) : (
                    <li className={classes.category} key={index}>
                      <Link href={category.url}>{category.name}</Link>
                    </li>
                  )}
                </ul>
              ))}
          </div>
        </BlogPostSidebarItem>
        <BlogPostSidebarItem heading="Tags">
          <div className={classes.tagContainer}>
            {blogTags &&
              blogTags?.map((tag, index) => (
                <div className={classes.tags} key={index}>
                  <Link href={`/blog/tag/${tag}`}>{tag}</Link>
                </div>
              ))}
          </div>
        </BlogPostSidebarItem>
        <BlogPostSidebarItem heading="Latest posts">
          <div className={classes.latestPosts}>
            {latestPosts &&
              latestPosts.length > 0 &&
              latestPosts.map((blogPost: BlogPost, index) => (
                <ul className={classes.list}>
                  <li className={classes.category} key={index}>
                    <Link href={blogPost.url}>{blogPost.heading}</Link>
                  </li>
                </ul>
              ))}
          </div>
          {/* These are the data types you can use on the latestPosts 
                date={blogPost.date}
                elementClick={`blogPost[${blogPost.heading}]_${blockIdentifier}`}
                heading={blogPost.heading}
                image={blogPost.image}
                url={blogPost.url} */}
        </BlogPostSidebarItem>
      </div>
    </Theme.Provider>
  );
}

export default BlogPostSidebar;
