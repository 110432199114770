import NextImage from 'next/image';
import cn from 'classnames';

import { Review as ReviewInterface, Theme } from '@interfaces';
import { RichTextContent } from '@components/RichTextContent';
import { useTheme } from '@utilities';

import classes from './Review.classes';

export interface ReviewProps extends Omit<ReviewInterface, 'key'> {
  blockTheme?: Theme;
  // eslint-disable-next-line react/no-unused-prop-types
  blockIdentifier: string;
}

function Review({ blockTheme, reviewerCaption, reviewerName, reviewText, reviewerImage }: ReviewProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  return (
    <blockquote className={classes.review}>
      <div className={classes.reviewText}>
        <RichTextContent content={reviewText} />
      </div>
      <cite className={classes.reviewer}>
        {reviewerImage && (
          <div className={cn(classes.reviewerImage, theme.border)}>
            <NextImage
              src={reviewerImage.src}
              alt={reviewerImage.alt || reviewerImage.src}
              width={reviewerImage.width}
              height={reviewerImage.height}
              sizes="100vw"
              quality={75}
              className={classes.image}
            />
          </div>
        )}
        <div>
          <div className={classes.reviewerName}>{reviewerName}</div>
          {reviewerCaption && <div className={classes.reviewerCaption}>{reviewerCaption}</div>}
        </div>
      </cite>
    </blockquote>
  );
}

export default Review;
