import { ClassesBuilder } from '@utilities';

const classesConfig = {
  select: {
    default:
      'appearance-none block w-full bg-white text-sm leading-none text-click-text p-1 border border-gray-300 rounded placeholder:text-gray-400 focus:outline-none focus:bg-white focus:border-blue-500 invalid:border-red-500',
  },
  invalid: 'border-red-500 focus:border-red-500',
  option: {
    default: 'p-3 checked:text-click-blue checked:bg-gray-100 focus:bg-gray-100 rounded-sm',
  },
};

export default new ClassesBuilder(classesConfig);
