import { Block } from '@interfaces';

export interface BlockListProps {
  blocks?: Block[];
}

function BlockList({ blocks = [] }: BlockListProps): JSX.Element | null {
  if (!blocks.length) return null;

  return (
    <>
      {blocks.map((block: Block) => {
        const blockName = block.name;
        const DynamicBlock = require(`../${blockName}/${blockName}`).default; // eslint-disable-line global-require

        return DynamicBlock ? <DynamicBlock key={block.key} {...block.props} /> : null;
      })}
    </>
  );
}

export default BlockList;
