import { ClassesBuilder } from '@utilities';

const classesConfig = {
  textarea: {
    default:
      'appearance-none block w-full bg-white text-sm leading-snug text-click-text p-3 border border-gray-300 rounded placeholder:text-gray-400 focus:outline-none focus:bg-white focus:border-blue-500 invalid:border-red-500',
  },
  invalid: 'border-red-500 focus:border-red-500',
};

export default new ClassesBuilder(classesConfig);
