import { FeaturedProducts as FeaturedProductsInterface } from '@interfaces';
import { ProductTile } from '@components/ProductTile';
import { ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { LinkProps } from '@components/Link';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './FeaturedProducts.classes';

export interface FeaturedProductsProps extends FeaturedProductsInterface {}

function FeaturedProducts({
  title,
  caption,
  captionImage,
  summary,
  link,
  button,
  products,
  blockTheme,
  blockIdentifier,
  customID,
}: FeaturedProductsProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }

  return (
    <Theme.Provider value={theme}>
      <div className={classes.container} id={customID}>
        <Content
          className={classes.content}
          caption={caption}
          captionImage={captionImage}
          title={title}
          richText={summary}
          buttonProps={buttonProps}
          linkProps={linkProps}
        />
        <div className={classes.tileWrapper}>
          {products &&
            products.map((product) => (
              <ProductTile
                key={product.key}
                name={product.name}
                url={product.url}
                strapline={product.strapline}
                icon={product.icon}
                theme={product.theme}
                elementClick={`product[${product.name}]_${blockIdentifier}`}
              />
            ))}
        </div>
      </div>
    </Theme.Provider>
  );
}

export default FeaturedProducts;
