import { ClassesBuilder } from '@utilities';

const classesConfig = {
  contentContainer: {
    md: 'md:mt-0 md:mb-0',
  },
  // comparisonList: 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4',
  grid: {
    default: 'grid gap-4 grid-cols-1',
  },
  comparisonItem: 'w-full p-4 lg:p-6 border border-click-blue rounded-lg bg-white transition hover:scale-105',
  heading: 'text-2xl font-bold text-click-blue',
  description: 'leading-6 text-click-blue',
  price: 'mt-2 text-4xl font-bold tracking-tight text-click-blue',
  button: 'mt-2',
  featureList: 'mt-8 space-y-2 text-sm leading-6 text-text',
  featureItem: 'flex items-baseline gap-x-2',
  featureIcon: 'w-4 h-4 text-[80%] text-white rounded-full p-1 inline-flex items-center justify-center',
};

export default new ClassesBuilder(classesConfig);
