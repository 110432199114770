import NextImage from 'next/image';
import NextLink from 'next/link';
import cn from 'classnames';

import { BioList as BioListInterface, Biography } from '@interfaces';
import { Button, ButtonProps } from '@components/Button';
import { Content } from '@components/Content';
import { Icon } from '@components/Icon';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './BioList.classes';

export interface BioListProps extends BioListInterface {}

function BioList({
  caption,
  captionImage,
  title,
  button,
  biographies,
  blockTheme,
  blockIdentifier,
  customID,
}: BioListProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
      style: 'rounded',
      size: 'small',
    };
  }

  return (
    <Theme.Provider value={theme}>
      <div id={customID}>
        {(caption || captionImage || title || button) && (
          <div className={classes.heading}>
            <Content caption={caption} captionImage={captionImage} title={title} />
            {button && <Button {...buttonProps}>{button.name}</Button>}
          </div>
        )}
        <div className={classes.biographies}>
          {biographies.map((biography: Biography) => {
            let bio =
              biography.bioType === 'Bio' ? (
                <div className={classes.card}>
                  {biography.image && (
                    <div className={classes.imageContainer}>
                      <NextImage
                        src={biography.image.src}
                        alt={biography.name}
                        width={biography.image.width}
                        height={biography.image.height}
                        sizes="100vw"
                        quality={75}
                        className={classes.image}
                      />
                    </div>
                  )}
                  <div className={classes.contentContainer}>
                    <div className={classes.name}>{biography.name}</div>
                    {biography.jobTitle && <div className={classes.jobTitle}>{biography.jobTitle}</div>}
                    {biography.url && (
                      <span className={cn(classes.link, theme.color, theme.colorHover)}>
                        Read more <Icon icon="fas fa-arrow-right" />
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <div className={classes.card}>
                  <div className={classes.vacancyContentContainer}>
                    <div className={cn(classes.vacancyName, theme.color)}>{biography.name}</div>
                    {biography.jobTitle && <div className={classes.vacancyTitle}>{biography.jobTitle}</div>}
                    {biography.url && (
                      <span className={cn(classes.vacancyLink, theme.color, theme.colorHoverAlt)}>
                        Read more <Icon icon="fas fa-arrow-right" />
                      </span>
                    )}
                  </div>
                </div>
              );

            if (biography.url) {
              bio = (
                <NextLink href={biography.url} data-element-click={`bio[${biography.name}]_${blockIdentifier}`}>
                  {bio}
                </NextLink>
              );
            }

            return <div key={biography.key}>{bio}</div>;
          })}
        </div>
      </div>
    </Theme.Provider>
  );
}

export default BioList;
