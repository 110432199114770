import NextImage from 'next/image';
import cn from 'classnames';

import { HeroUnit as HeroUnitInterface } from '@interfaces';
import { RichTextContent } from '@components/RichTextContent';
import { Button } from '@components/Button';
import { Link } from '@components/Link';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './HeroUnit.classes';

export interface HeroUnitProps extends Omit<HeroUnitInterface, 'centerAligned'> {}

function HeroUnit({
  caption,
  captionImage,
  title,
  summary,
  mobileImage,
  desktopImage,
  button,
  link,
  blockTheme,
  blockIdentifier,
}: HeroUnitProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  return (
    <Theme.Provider value={theme}>
      <div className={classes.heroUnit}>
        <div className={classes.container}>
          <div className={classes.contentContainer}>
            {caption && <div className={cn(classes.caption, theme.color)}>{caption}</div>}
            {captionImage && (
              <div className={classes.captionImageContainer}>
                <NextImage
                  src={captionImage.src}
                  alt={captionImage.alt || ''}
                  width={captionImage.width}
                  height={captionImage.height}
                  sizes="100vw"
                  quality={75}
                  className={classes.captionImage}
                  priority
                />
              </div>
            )}
            {title && <h1 className={classes.title}>{title}</h1>}
            {summary && (
              <div className={classes.summary}>
                <RichTextContent content={summary} />
              </div>
            )}
            {(button || link) && (
              <div className={classes.actionsContainer}>
                {button && (
                  <Button
                    href={button.href}
                    linkType={button.linkType}
                    target={button.target}
                    elementClick={`button[${button.name}]_${blockIdentifier}`}
                  >
                    {button.name}
                  </Button>
                )}
                {link && (
                  <Link
                    href={link.href}
                    linkType={link.linkType}
                    target={link.target}
                    icon="fas fa-arrow-right"
                    elementClick={`link[${link.name}]_${blockIdentifier}`}
                  >
                    {link.name}
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className={cn(classes.imageContainer, theme.curve)}>
            {desktopImage && (
              <div className={classes.desktopImageContainer}>
                <NextImage
                  src={desktopImage.src}
                  alt={desktopImage.alt || desktopImage.src}
                  width={desktopImage.width}
                  height={desktopImage.height}
                  sizes="100vw"
                  quality={75}
                  className={classes.desktopImage}
                  priority
                />
              </div>
            )}
            {mobileImage && (
              <div className={classes.mobileImageContainer}>
                <NextImage
                  src={mobileImage.src}
                  alt={mobileImage.alt || mobileImage.src}
                  width={mobileImage.width}
                  height={mobileImage.height}
                  sizes="100vw"
                  quality={75}
                  className={classes.mobileImage}
                  priority
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Theme.Provider>
  );
}

export default HeroUnit;
