import { Review, ReviewList as ReviewListInterface } from '@interfaces';
import { ButtonProps } from '@components/Button';
import { LinkProps } from '@components/Link';
import { ReviewCard } from '@components/ReviewCard';
import { Content } from '@components/Content';
import { useTheme } from '@utilities';
import { Theme } from '@contexts';

import classes from './ReviewList.classes';

export interface ReviewListProps extends ReviewListInterface {}

function ReviewList({
  caption,
  title,
  summary,
  reviews,
  blockTheme,
  blockIdentifier,
  customID,
  button,
  link,
}: ReviewListProps): JSX.Element {
  const theme = useTheme({ blockTheme });

  let buttonProps: ButtonProps | undefined;

  if (button) {
    buttonProps = {
      ...button,
      elementClick: `button[${button.name}]_${blockIdentifier}`,
      children: button.name,
    };
  }

  let linkProps: LinkProps | undefined;

  if (link) {
    linkProps = {
      ...link,
      icon: 'fas fa-arrow-right',
      elementClick: `link[${link.name}]_${blockIdentifier}`,
      children: link.name,
    };
  }

  return (
    <Theme.Provider value={theme}>
      <Content title={title} caption={caption} richText={summary} linkProps={linkProps} buttonProps={buttonProps} />
      <div className={classes.container} id={customID}>
        {reviews.map((review: Review) => (
          <ReviewCard
            key={review.key}
            reviewText={review.reviewText}
            reviewRating={review.reviewRating}
            reviewerName={review.reviewerName}
            reviewerCaption={review.reviewerCaption ? review.reviewerCaption : ''}
          />
        ))}
      </div>
      {/* <Pagination
        basePath="/"
        pageNumber={pagination.pageNumber}
        totalPages={pagination.totalPages}
        pageSize={pagination.pageSize}
        totalItems={pagination.totalItems}
      /> */}
    </Theme.Provider>
  );
}

export default ReviewList;
