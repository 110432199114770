import { NextSeo } from 'next-seo';

import { BlogPostPage as BlogPostPageInterface, PageSection as PageSectionInterface } from '@interfaces';

import { BlogPostHeroUnit } from '@components/BlogPostHeroUnit';
import BlogPostSidebar from '@components/BlogPostSidebar/BlogPostSidebar';
import { RichTextContent } from '@components/RichTextContent';
import { PageSection } from '@components/PageSection';
import { Theme } from '@contexts';

import classes from './BlogPostPage.classes';

export interface BlogPostPageProps extends BlogPostPageInterface {}

function Page({
  blogPostHeroUnit,
  article,
  sections,
  seoProps,
  sidebar,
  blogCategory,
  blogTags,
  theme,
}: BlogPostPageProps): JSX.Element {
  return (
    <Theme.Provider value={theme}>
      {seoProps && <NextSeo {...seoProps} />}
      <main className={classes.pageMain}>
        <div className={classes.pageContent}>
          <div className={classes.blogContainer}>
            <div className={classes.blogArea}>
              <PageSection
                key="blogPostHeroUnit"
                areas={[]}
                settings={{
                  backgroundColor: 'bg-white',
                  spacingTop: 'pt-4 sm:pt-6 md:pt-8',
                  spacingBottom: 'pb-4 sm:pb-6 md:pb-8',
                }}
              >
                <BlogPostHeroUnit {...blogPostHeroUnit} />
              </PageSection>
              <PageSection
                key="blogPostArticle"
                areas={[]}
                settings={{
                  backgroundColor: 'bg-white',
                  spacingTop: 'pt-0',
                  spacingBottom: 'pb-4 sm:pb-6 md:pb-8',
                }}
              >
                <RichTextContent content={article} />
              </PageSection>
              {sections && sections.map((section: PageSectionInterface) => <PageSection {...section} />)}
            </div>
            <div className={classes.blogSidebar}>
              <BlogPostSidebar
                categories={sidebar?.categories}
                tags={sidebar?.tags}
                blogCategory={blogCategory}
                blogTags={blogTags}
                latestPosts={sidebar?.latestPosts}
                theme={theme}
              />
            </div>
          </div>
        </div>
      </main>
    </Theme.Provider>
  );
}

export default Page;
