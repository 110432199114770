import { ClassesBuilder } from '@utilities';

const classesConfig = {
  promotion: 'grid row-auto overflow-hidden rounded-xl bg-click-blue grid-cols-12',
  contentContainer: {
    default: 'flex flex-col items-start justify-start m-8 col-span-12',
    md: 'md:m-10 md:col-span-5 md:items-center md:justify-center',
  },

  imageContainer: {
    default: 'self-end col-span-12 overflow-hidden h-52',
    '2xs': '2xs:h-64',
    md: 'md:h-96 md:col-span-7',
  },
  imageContainerInner: {
    default: 'ml-8 -mr-10',
    md: 'md:ml-0 md:mt-10',
  },
  image: 'self-end w-full h-auto z-[1]',
};

export default new ClassesBuilder(classesConfig);
